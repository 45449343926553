import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';
import Feature from 'ol/Feature';

@Injectable()
export class GraveInfoToolService extends GenericToolService {

  @Output() graveInfoReceived = new EventEmitter();

  name = 'GraveInfo';
  infoLayers: any[];
  tempLayer: any;
  registerableFunc: any;

  activate(mapService: MapService) {

    this.mapService = mapService;
    this.map = this.mapService.map;

    this.tempLayer = this.mapService.addTemporaryLayerWithStyle('GraveInfo', this.styleFactory.createPolygonStyle());
    this.tempLayer.layerConfig.printable = false;

    this.infoLayers = this.mapService.getLayersByProperty('info', true);

    if (!this.infoLayers || this.infoLayers.length === 0) {
      console.error('[ERROR] No info layers specified in map configuration. Grave Info tool will not work correctly');
    }

    // passing scope to singleclick not working in angular;
    const me = this;
    this.registerableFunc = function (evt) {
      me.executeGetFeatureInfo(evt);
    };

    this.map.on('singleclick', this.registerableFunc);

  }

  getRoutableLayer(layers) {

    for (const i in layers) {
      if (layers[i].layerConfig && layers[i].layerConfig.routingInfo) {
        return layers[i];
      }
    }
    return null;
  }

  replaceUrlParam (url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '';
    }
    const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
  }

  executeGetFeatureInfo (evt) {

    const url = this.generateGetFeatureInfoUrl(evt);

    this.mapHttpService.getFeatureInfo(url).subscribe(response => {
      if (response && response.features && response.features.length > 0) {
        this.addFeaturesToMap(response.features, this.calculateFeatureProjection(response));

        this.mapHttpService.getInfo(this.buildInfoRequest(response.features)).subscribe( res => {
          if (res && res.length > 0) {
            const id = res[0].attributes[0].id;
            this.retrieveGraveDetails(id);
          }
        });
      }
    });
  }

  calculateFeatureProjection(response: any) {
    if (response.crs && response.crs.properties && response.crs.properties.name) {
      const crsName = response.crs.properties.name;
      return 'EPSG:' + crsName.substring(crsName.length - 4);
    } else {
      return this.mapService.mapConfig.dataProjection;
    }

  }

  retrieveGraveDetails(id: number) {
    const url = '/cmentarz_grobs/' + id;
    this.mapHttpService.executeGetApiRequest(url).subscribe(res => {
      this.graveInfoReceived.emit(this.buildGraveInfo(res));
    });

  }

  buildGraveInfo(res: any): any {
    return {
      id: res.id,
      kwatera: res.kwatera.nazwa,
      rzad: res.rzad.nazwa,
      grob: res.nrGrobu,
      pochowani: res.pochowani
    };
  }

  buildInfoRequest(features: any) {
    const infoRequest = [];
    for (const feature of features) {
      const fidParts = feature.id.split('.');
      const properties = feature.properties;
      const layerName = fidParts[0];
      const layer = this.findInfoLayer(layerName);
      let key: string;
      let value: string;
      if (layer.layerConfig.infoConfig && layer.layerConfig.infoConfig.idProperty) {
        key = layer.layerConfig.infoConfig.idProperty;
        value = properties[layer.layerConfig.infoConfig.idProperty];
      } else {
        key = 'id';
        value = properties.id;
      }
      const request = {
        layerName: layerName
      }
      request[key] = value;
      infoRequest.push(request);
    }
    return {items: infoRequest};
  }

  findInfoLayer(layerName: string) {
    for (const layer of this.infoLayers) {
      if (layer.layerConfig.layerName.indexOf(layerName) >= 0) {
        return layer;
      }
    }
  }
  addFeaturesToMap(features, featureProjection) {
    this.tempLayer.getSource().clear();
    const mapFeatures = [];
    for (const f of features) {
      const geom = this.mapService.fromGeojson(f.geometry).transform(featureProjection, this.mapService.getSrs());

      const feature = new Feature({
        geometry: geom
      });
      mapFeatures.push(feature);
      break; // only first feature will be added, remove this break to handle all features
    }

    this.tempLayer.getSource().addFeatures(mapFeatures);
  }

  generateGetFeatureInfoUrl(evt) {

    const viewResolution = this.map.getView().getResolution();
    let layers = '';
    let first = true;
    for (const i in this.infoLayers) {
      if (first) {
        layers = this.infoLayers[i].getSource().getParams().LAYERS;
        first = false;
      } else {
        layers += ',' + this.infoLayers[i].getSource().getParams().LAYERS;
      }
    }

    const params = {
      'INFO_FORMAT' : 'application/json',
      'QUERY_LAYERS' : layers,
      'LAYERS' : layers,
      'FEATURE_COUNT' : 50
    };

    params['BUFFER'] = 2;

    return this.infoLayers[0].getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, this.mapService.getSrs(), params);

  }

  deactivate() {
    if (this.registerableFunc) {
      this.map.un('singleclick', this.registerableFunc);
    }
    this.map.removeLayer(this.tempLayer);
  }
}
