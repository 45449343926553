import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';

import * as moment_ from 'moment';

const moment = moment_;

class DateConfig {
  startView: 'month' | 'year' | 'multi-year';
  touchUi: boolean;
  minDate: moment_.Moment;
  maxDate: moment_.Moment;
}

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimepickerComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() placeholderDate: string;
  @Input() placeholderStartTime: string;
  @Input() placeholderEndTime: string;
  @Input() startModel: Date;
  @Input() endModel: Date;
  @Input() purpose: string;
  @Input() hasEndTime = false;
  @Input() dateOnly: boolean;
  @Input() requiredField: boolean;

  @Output() startDateUpdate = new EventEmitter<Date>();
  @Output() endDateUpdate = new EventEmitter<Date>();

  public pickerId: string = '_' + Math.random().toString(36).substr(2, 9);

  public dateForm: FormControl;
  public timeFormGroup: FormGroup;
  public startTime: FormControl;
  public endTime: FormControl;

  public startMomentDate: moment_.Moment;
  public endMomentDate: moment_.Moment;
  public config: DateConfig;

  private _startHour: number;
  private _startMinute: number;
  private _endHour: number;
  private _endMinute: number;

  constructor(private adapter: DateAdapter<any>) { }

  ngOnInit() {
    this.adapter.setLocale('pl-PL');
    this.config = new DateConfig();

    if (this.purpose === 'birthday') {
      this.config.startView = 'multi-year';
      this.config.maxDate = moment().add(-15, 'year');
      this.config.minDate = moment().add(-90, 'year');
      this.dateOnly = true;
    } else {
      this.config.startView = 'month';
      this.config.maxDate = moment().add(100, 'year');
      this.config.minDate = moment().add(-100, 'year');
    }

    if (window.screen.width < 767) {
      this.config.touchUi = true;
    }

    this.startMomentDate = this._momentDate(this.startModel);
    this.endMomentDate = this._momentDate(this.endModel);

    this.dateForm = new FormControl(this.startMomentDate);

    if (this.disabled) {
      this.dateForm.disable();
    }

    const startTimeValue = this.endModel ? this.startMomentDate.format('HH:mm') : '';
    this.startTime = new FormControl({value: startTimeValue, disabled: !this.startModel});
    const endTimeValue = this.endModel ? this.endMomentDate.format('HH:mm') : '';
    this.endTime = new FormControl({value: endTimeValue, disabled: !this.endModel});
    this.timeFormGroup = new FormGroup({
      startTime: this.startTime,
      endTime: this.endTime
    });
  }

  private _momentDate(model: Date) {
    if (model) {
      const mom = moment(model);
      if (mom.isBefore(moment('1900-01-01'))) {
        return moment();
      } else {
        return mom;
      }
    }
  }

  public dateChange(date: MatDatepickerInputEvent<any>) {
    if (moment.isMoment(date.value)) {
      this.startMomentDate = moment(date.value);
      this.startMomentDate = this.startMomentDate.set('hour', +this._startHour);
      this.startMomentDate = this.startMomentDate.set('minute', +this._startMinute);

      this.endMomentDate = moment(date.value);
      this.endMomentDate = this.endMomentDate.set('hour', +this._startHour);
      this.endMomentDate = this.endMomentDate.set('minute', +this._startMinute);

      this.timeFormGroup.get('startTime').enable();
      this.timeFormGroup.get('endTime').enable();

      if (this.dateOnly) {
        this.startMomentDate = this.startMomentDate.utc(true);
        this.endMomentDate = this.endMomentDate.utc(true);
      }

      const newStartDate = this.startMomentDate.toDate();
      const newEndDate = this.endMomentDate.toDate();

      this.startModel = newStartDate;
      this.startDateUpdate.emit(newStartDate);

      this.endModel = newEndDate;
      this.endDateUpdate.emit(newEndDate);
    }
  }

  public startTimeChange(time: string) {
    const splitted = time.split(':');
    const hour = splitted[0];
    const minute = splitted[1];

    this.startMomentDate = this.startMomentDate.set('hour', +hour);
    this._startHour = +hour;
    this.startMomentDate = this.startMomentDate.set('minute', +minute);
    this._startMinute = +minute;

    const newDate = this.startMomentDate.toDate();
    this.startModel = newDate;
    this.startDateUpdate.emit(newDate);
  }

  public endTimeChange(time: string) {
    const splitted = time.split(':');
    const hour = splitted[0];
    const minute = splitted[1];

    this.endMomentDate = this.endMomentDate.set('hour', +hour);
    this._endHour = +hour;
    this.endMomentDate = this.endMomentDate.set('minute', +minute);
    this._endMinute = +minute;

    const newDate = this.endMomentDate.toDate();
    this.endModel = newDate;
    this.endDateUpdate.emit(newDate);
  }
}
