import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-measurement-panel',
  templateUrl: './measurement-panel.component.html',
  styleUrls: ['./measurement-panel.component.scss']
})
export class MeasurementPanelComponent implements OnInit {

  @Output() measureTypeChanged = new EventEmitter();
  @Output() measureCleared = new EventEmitter();
  @Output() measureFinished = new EventEmitter();

  public selectedType = 'LENGTH';

  constructor() { }

  ngOnInit() {
  }

  clearMeasurements() {
    this.measureCleared.emit();
  }

  onSelectionChange() {
    this.measureTypeChanged.emit(this.selectedType);
  }
}
