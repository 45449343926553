import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend-panel',
  templateUrl: './legend-panel.component.html',
  styleUrls: ['./legend-panel.component.scss']
})
export class LegendPanelComponent implements OnInit {

  legendUrl: string;

  constructor() { }

  ngOnInit() {
  }

  setLegendUrl(url: string) {
    this.legendUrl = url;
  }
}
