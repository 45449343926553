import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DrawingEvent} from '../../../enums/drawing-event';
import {SnappingPanelComponent} from '../../snapping-panel/snapping-panel.component';
import {MapEventBusService} from '../../../services/map/map-event-bus-service';

@Component({
  selector: 'app-drawing-panel',
  templateUrl: './drawing-panel.component.html',
  styleUrls: ['./drawing-panel.component.scss']
})
export class DrawingPanelComponent implements OnInit {

  @Output() drawingEvent = new EventEmitter();
  @Output() snappingConfigChanged = new EventEmitter();
  @ViewChild('drawSnapping', { static: false }) contentPlaceholder: SnappingPanelComponent;

  initiated = false;
  selectedTool: string;
  editableLayers: any[] = [];
  selectedLayer: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private eventBus: MapEventBusService) { }

  ngOnInit() {}

  registerObservables() {
    this.initiated = true;

    this.contentPlaceholder.snappingConfigChanged.subscribe(snappingConfig => {
      this.snappingConfigChanged.emit(snappingConfig);
    });

    this.eventBus.toolDeactivated.subscribe(() => {
      this.cleanupTool();
    });

    this.eventBus.drawingSessionEnded.subscribe(() => {
      this.cleanup();
    });

    this.eventBus.changeLayerCancelled.subscribe((layerName) => {
      this.restorePreviousLayer(layerName);
    });

    this.eventBus.selectEditableLayer.subscribe((layerName) => {
      this.selectedLayer = layerName;
      this.selectEditingLayer(layerName);
    });
  }

  cleanupTool() {
    this.selectedTool = undefined;
  }

  cleanup() {
    this.cleanupTool();
    this.selectedLayer = undefined;
  }

  public loadEditableLayers(layers: any[]) {
    for (const layer of layers) {
      if (layer.layerConfig) {
        this.editableLayers.push({name: layer.layerConfig.name, layerName: layer.layerConfig.layerName});
      }
    }
  }

  selectEditingLayer(layer: any) {
    this.eventBus.drawingEvent.emit({event: DrawingEvent.CHANGE_VECTOR_LAYER, layerName: layer});

    this.changeDetectorRef.detectChanges();
    if (!this.initiated) {
      this.registerObservables();
    }
  }

  restorePreviousLayer(layerName: string) {
    this.selectedLayer = layerName;
  }

  activateTool(type: string) {
    if (this.selectedTool === type) {
      this.selectedTool = undefined;
    } else if (type === 'add' || type === 'edit' || type === 'move' || type === 'delete' || type === 'attributes') {
      this.selectedTool = type;
    }
    this.eventBus.drawingEvent.emit({event: DrawingEvent.ACTIVATE_DRAWING_TOOL, toolName: type});
  }

  updateConfiguration(type: string, event: any) {
    this.eventBus.drawingEvent.emit({event: DrawingEvent.DRAWING_CONFIG_CHANGED, configName: type, value: event.checked});
  }
}
