import { GenericToolService } from './generic-tool-service';
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import { get as getProjection, transform, transformExtent} from 'ol/proj.js';
import {getCenter, getSize as getExtentSize} from 'ol/extent.js';
import GeometryLayout from 'ol/geom/GeometryLayout';
import GeoJSON from 'ol/format/GeoJSON.js';
import Translate from 'ol/interaction/Translate.js';
import Collection from 'ol/Collection.js';
import {Injectable} from '@angular/core';
import {MapService} from '../services/map-service';

@Injectable()
export class PrintToolService extends GenericToolService {

  printTemplateConfig = {
    'address1': 'Starosta Opolski',
    'address2': 'ul. 1 maja 29',
    'address3': '45-068 Opole',
    'address4': 'tel. 666 6 666'
  }
  positioningActive = false;
  translateInteraction: Translate;

  defaultDPI = 75;
  widthToAppend = 25;
  heightToAppend = 35;
  firstRender = true;

  name = 'Print';
  printConfig: any;
  printPageLayer: any;

  currentScale: number;
  currentTemplate: any;
  currentRotation = 0;

  activate(mapService: MapService, printConfig: any) {
    this.mapService = mapService;
    this.map = this.mapService.map;
    this.initPrint(printConfig);
  }

  deactivate() {
    this.map.removeInteraction(this.translateInteraction);
    this.map.removeLayer(this.printPageLayer);
    this.firstRender = true;
  }

  initPrint(printConfig: any) {
    this.printConfig = printConfig;
    this.currentTemplate = this.printConfig.selectedTemplate;
    this.printPageLayer = this.mapService.addTemporaryLayerWithStyle('Wydruk', this.styleFactory.createPrintPageStyle());
    this.printPageLayer.layerConfig.printable = false;

    this.renderPrintPage(this.calculateExtent());

  }

  togglePositioning() {
    if (!this.positioningActive) {
      const feature = this.printPageLayer.getSource().getFeatures()[0];
      this.translateInteraction = new Translate({
        features : new Collection([feature])
      });

      this.map.addInteraction(this.translateInteraction);
      this.positioningActive = true;

    } else {
      this.map.removeInteraction(this.translateInteraction);
      delete this.translateInteraction;
      this.positioningActive = false;
    }
  }

  print(data: any, srs: string, layers: any[], maxExtent: any[]) {

    const params = this.buildPrintParams(data, srs, layers, maxExtent);

    if (params.hasOwnProperty('pageSize')) {
      const pageSize = params.pageSize.split(' ');
      if (parseInt(pageSize[0], 10) > 14400 || parseInt(pageSize[1], 10) > 14400) {
        console.log('Maksymalny rozmiar wydruku to 14400 na 14400. Obecne wartości to ' + pageSize[0] + ' na ' + pageSize[1]);
        return;
      }
    }

    const url = encodeURI(this.printConfig.printURL + '?spec=' + JSON.stringify(params));
    window.open(url, '_blank');
  }

  handlePropertyChange(change) {

    if (change.property === 'scale') {
      this.currentScale = change.value;
    } else if (change.property === 'rotation') {
      this.currentRotation = change.value;
    } else if (change.property === 'format') {
      const template = this.getTemplate(change.value);
      if (template) {
        this.currentTemplate = template;
      }
    }

    this.redrawPrintPage(this.calculateExtent());
  }

  getTemplate(templateName: string): any {

    for (let i in this.printConfig.layouts) {
      if (this.printConfig.layouts[i].name === templateName) {
        return this.printConfig.layouts[i];
      }
    }
    return null;
  }

  renderPrintPage(extent) {
    const feature = this.createPageFeature(extent);
    this.printPageLayer.getSource().addFeature(feature);
  }

  redrawPrintPage(extent) {
    this.printPageLayer.getSource().clear();
    this.renderPrintPage(extent);
  }

  createPageFeature(extent) {
    let geometry;

    if (!extent) {
      extent = this.calculateExtent();
    }

    if (this.currentRotation === 0) {
      geometry = this.fromExtent(extent);
    } else {
      geometry = this.rotatePolygon(extent, this.currentRotation);
    }

    return new Feature(geometry);
  }

  calculateExtent() {
    const scale = this.currentScale;
    const rotation = 0;
    const width = this.getCurrentTemplateWidth();
    const height = this.getCurrentTemplateHeight();
    let center;
    const size = [width, height];
    const projection = this.map.getView().getProjection();

    if (this.firstRender) {
      center = this.map.getView().getCenter();
      this.firstRender = false;
    } else {
      center = getCenter(this.printPageLayer.getSource().getExtent());
    }

    const extent = this.getForViewAndSize(projection, center, scale, rotation, size);
    return extent;
  }

  getClosestScale(printConfig: any, currentScale: number) {
    const scales = printConfig.scales;

    for ( let i = 0; i < scales.length; i++) {
      const scale = scales[i].value;
      let closestScale: number;
      if (currentScale < scale) {
        if (i > 1) {
          closestScale = scales[i - 2].value;
        } else {
          closestScale = scales[0].value;
        }
        this.currentScale = closestScale;
        return closestScale;
      }
    }
    // if no scale picked, pick the max one
    return scales[scales.length - 1].value;
  }

  getForViewAndSize (projection, center, scale, rotation, size) {

    const proj2180 = getProjection('EPSG:2180');
    center = transform(center, projection, proj2180);

    const mpu = projection.getMetersPerUnit();
    const inchesPerMeter = 39.37;
    const resolution = scale / (mpu * inchesPerMeter * this.defaultDPI);

    const dx = resolution * size[0] / 2;
    const dy = resolution * size[1] / 2;
    const cosRotation = Math.cos(rotation);
    const sinRotation = Math.sin(rotation);

    const xs = [-dx, -dx, dx, dx];

    const ys = [-dy, dy, -dy, dy];
    let x, y;
    for (let i = 0; i < 4; ++i) {
      x = xs[i];
      y = ys[i];
      xs[i] = center[0] + x * cosRotation - y * sinRotation;
      ys[i] = center[1] + x * sinRotation + y * cosRotation;
    }

    const minX = Math.min.apply(null, xs);
    const minY = Math.min.apply(null, ys);
    const maxX = Math.max.apply(null, xs);
    const maxY = Math.max.apply(null, ys);

    return transformExtent([minX, minY, maxX, maxY], proj2180, projection);

  }

  rotatePolygon(extent, angle) {
    const center = getCenter(extent);
    const p1 = this.rotatePoint([extent[0], extent[1]], angle, center);
    const p2 = this.rotatePoint([extent[0], extent[3]], angle, center);
    const p3 = this.rotatePoint([extent[2], extent[3]], angle, center);
    const p4 = this.rotatePoint([extent[2], extent[1]], angle, center);
    const flatCoords = [p1[0], p1[1], p2[0], p2[1], p3[0], p3[1], p4[0], p4[1], p1[0], p1[1]];
    const polygon = new Polygon(flatCoords, GeometryLayout.XY, [flatCoords.length]);
    return polygon;
  }

  rotatePoint(point, angle, origin) {
    angle = angle * (-1);
    angle *= Math.PI / 180;
    const radius = this.distanceTo(point, origin);
    const theta = angle + Math.atan2(point[1] - origin[1], point[0] - origin[0]);
    const x = origin[0] + (radius * Math.cos(theta));
    const y = origin[1] + (radius * Math.sin(theta));
    return [x, y];
  }

  distanceTo (point, center) {
    return Math.sqrt(Math.pow(point[0] - center[0], 2) + Math.pow(point[1] - center[1], 2));
  }

  getCurrentTemplateWidth() {
    let width = this.currentTemplate.map.width;

    if (width === 0) {
      width = this.getTemplateWidthFromName(this.currentTemplate.name);
    }
    return width + this.widthToAppend;
  }

  getCurrentTemplateHeight() {
    let height = this.currentTemplate.map.height;
    if (height === 0) {
      height = this.getTemplateHeightFromName(this.currentTemplate.name);
    }
    return height + this.heightToAppend;
  }

  getTemplateWidthFromName(name: string) {

    if (name.indexOf('A4') > -1) {
      return 595;
    } else if (name.indexOf('A3') > -1) {
      return 842;
    } else {
      return 595;
    }
  }

  getTemplateHeightFromName(name) {
    if (name.indexOf('A4') > -1) {
      return 842;
    } else if (name.indexOf('A3') > -1) {
      return 1188;
    } else {
      return 842;
    }
  }

  fromExtent(extent: any[]) {

    const minX = extent[0];
    const minY = extent[1];
    const maxX = extent[2];
    const maxY = extent[3];
    const flatCoordinates =
      [minX, minY, minX, maxY, maxX, maxY, maxX, minY, minX, minY];

    return new Polygon(flatCoordinates, GeometryLayout.XY, [flatCoordinates.length]);

  }

  buildPrintParams(data, srs: string, layers: any[], maxExtent: any[]): any {

    const center = getCenter(this.printPageLayer.getSource().getExtent());

    const spec = {
      layout : this.currentTemplate.name,
      pages : [{
        mapTitle : data.title,
        comment : 'test',
        center : center,
        scale : this.currentScale,
        rotation : this.currentRotation || 0
      }],
      dpi : data.dpi,
      outputFormat : data.type,
      units : getProjection(srs).getUnits(),
      srs : srs,
      layers : this.getLayersPrintConfig(layers, maxExtent),
      geodetic: true
    };

    if (this.currentTemplate.map.width === 0) {
      const extent = this.printPageLayer.getSource().getExtent();
      const size = this.getSize(extent);
      spec['pageSize'] = size[0] + ' ' + size[1];
      spec['mapWidth'] = size[0] - this.widthToAppend;
      spec['mapHeight'] = size[1] - this.heightToAppend;
    }

    spec['mapTitle'] = data.title;
    spec['rotation'] = 360 - this.currentRotation || 0;
    spec['scaleInfo'] = Math.round(this.currentScale);

    if (this.printTemplateConfig) {
      spec['address1'] = this.printTemplateConfig.address1;
      spec['address2'] = this.printTemplateConfig.address2;
      spec['address3'] = this.printTemplateConfig.address3;
      spec['address4'] = this.printTemplateConfig.address4;
    }

    return spec;

  }

  getLayersPrintConfig(layers: any[], maxExtent: any[]) {

    const result = [];

    for ( let i = 0; i < layers.length; i++) {
      const config = this.getLayerPrintConfig(layers[i], maxExtent);
      if (config) {
        result.push(config);
      } else {
        console.error('Unable to retrieve print config for layer ' + layers[i].text);
      }
    }

    return result;
  }

  getLayerPrintConfig(layer, maxExtent: any[]) {
    let config;

    if (layer.layerConfig.type === 'wms' || layer.layerConfig.type === 'gwc') {
      config = this.getLayerPrintWmsConfig(layer);
    } else if (layer.layerConfig.type === 'osm') {

      config = {
        'baseURL' : 'http://tile.openstreetmap.org/',
        'opacity' : 1,
        'singleTile' : false,
        'customParams' : {},
        'type' : 'Osm',
        'overview' : true,
        'extension' : 'png',
        'maxExtent' : maxExtent,
        'tileSize' : [256, 256],
        'resolutions' : layer.getSource().getTileGrid().getResolutions()
      };

    } else {
      config = this.getLayerJsonConfig(layer);
    }

    return config;
  }

  getLayerJsonConfig(layer) {
    const features = layer.getSource().getFeatures();

    for ( let i = 0; i < features.length; i++) {
      features[i].set('_gx_style', 1);
    }

    const parser = new GeoJSON();
    let geojson = parser.writeFeatures(features);
    geojson = JSON.parse(geojson);

    const config = {
      type : 'Vector',
      'styles' : {
        '1' : {
          'strokeColor' : 'red',
          'strokeOpacity' : 0.5,
          'fillOpacity' : 0.2,
          'fillColor' : 'red'
        }
      },
      'styleProperty' : '_gx_style',
      geoJson : geojson
    };

    return config;

  }

  getLayerPrintWmsConfig(layer: any) {
    const config = {};

    if (layer.layerConfig.type === 'wms') {
      config['baseURL'] = (layer.getSource().url_ || layer.getSource().urls[0]);
    } else if (layer.layerConfig.type === 'gwc'){
      if (layer.layerConfig.url) {
        config['baseURL'] = layer.layerConfig.url + '?';
      } else {
        const url = (layer.getSource().url_ || layer.getSource().urls[0]) + '?';
        config['baseURL'] = url.replace('gwc', 'wms');
      }
    }

    config['opacity'] = layer.get('opacity');
    config['singleTile'] = !!layer.layerConfig.singleTile;
    config['stypes'] = [];
    config['format'] = layer.layerConfig.format;
    config['layers'] = [layer.layerConfig.layerName];
    config['customParams'] = {
      'TRANSPARENT' : true
    };

    config['overview'] = !!layer.layerConfig.baseLayer;

    config['type'] = 'wms';

    return config;
  }

  getSize(extent) {
    const size = getExtentSize(extent);
    const resolution = this.mapService.getResolutionForScaleAndDpi(this.map, this.currentScale, this.defaultDPI);
    return [size[0] / resolution, size[1] / resolution];
  }
}
