import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-grave-info-panel',
  templateUrl: './grave-info-panel.component.html',
  styleUrls: ['./grave-info-panel.component.scss']
})
export class GraveInfoPanelComponent implements OnInit {

  private _router: Router;
  infoData: any;

  constructor(router: Router) {
    this._router = router;
  }

  ngOnInit() {
  }

  setInfoData(data: any) {
    this.infoData = data;
  }

  showDetails() {
    this._router.navigate(['/cemetery/graves/card'], { queryParams: { id: this.infoData.id } });
  }
}
