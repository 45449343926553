import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { MatConfirmDialogComponent } from '../components/mat-confirm-dialog/mat-confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  private _matSnackBarConfig: MatSnackBarConfig;
  private _matSnackBar: MatSnackBar;

  constructor(
    private injector: Injector,
    private _dialog: MatDialog
  ) {
    this._matSnackBar = this.injector.get(MatSnackBar);
    this._matSnackBarConfig = {
      duration: 5000,
      direction: 'rtl',
    };
  }

  openConfirmDialog(msg) {
    return this._dialog.open(MatConfirmDialogComponent, {
      width: '640px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      position: { top: '90px' },
      data: {
        message: msg
      }
    });
  }

}
