import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-editing-panel',
  templateUrl: './editing-panel.component.html',
  styleUrls: ['./editing-panel.component.scss']
})
export class EditingPanelComponent implements OnInit {

  @Output() clearDrawing = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.clearDrawing.emit();
  }
}
