import { Component, OnInit } from '@angular/core';
import {MapEventBusService} from '../../../services/map/map-event-bus-service';

@Component({
  selector: 'app-draw-info-panel',
  templateUrl: './draw-info-panel.component.html',
  styleUrls: ['./draw-info-panel.component.scss']
})
export class DrawInfoPanelComponent implements OnInit {

  info: string;

  constructor(private eventBus: MapEventBusService) { }

  ngOnInit() {
    this.eventBus.drawChanged.subscribe(info => {
      this.setInfo(info);
    });
    this.eventBus.drawSaved.subscribe(() => {
      this.cleanup();
    });
    this.eventBus.drawingSessionEnded.subscribe(() => {
      this.cleanup();
    });
  }

  cleanup() {
    this.info = undefined;
  }

  setInfo(info: string) {
    this.info = 'Zmiany w bieżącej sesji edycyjnej: ' +  '<br/>' + info;
  }
}
