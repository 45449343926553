import { Directive, OnInit, ViewContainerRef } from '@angular/core';
import { ToastService } from './toast.service';

@Directive({
  selector: '[appToast]'
})

export class ToastDirective implements OnInit {

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this._toastService.init(this._viewContainerRef);
  }
}
