import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './common/components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from './common/components/login/services/login.service';
import { AuthGuard } from './common/app-permission/auth.guard';
import { AuthInterceptor } from './common/app-permission/auth.interceptor';
import { AppInitService } from './core/services/app-init.service';
import { ToastService } from './common/services/toast.service';
import { ToastDirective } from './common/services/toast.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { APP_INITIALIZER } from '@angular/core';
import { AppInitializerService } from './core/services/app-initializer.service';
import { MenuService } from './common/services/menu.service';
import {DateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {ApiUsersService} from './administration/users/services/users-api.service';
import {UsersService} from './administration/users/services/users.service';
import {ApiRepairsService} from './wod-kan/repairs/services/repairs-api.service';
import {RepairsService} from './wod-kan/repairs/services/repairs.service';
import {NativeDateTimeAdapter} from 'ng-pick-datetime/date-time/adapter/native-date-time-adapter.class';
import {Platform, PlatformModule} from '@angular/cdk/platform';
import {DatetimepickerComponent} from './common/datetime-picker/datetime-picker.component';

export function init_app(appInitializerService: AppInitializerService)  {
  return () => appInitializerService.init();
}
export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Anuluj';
  setBtnLabel = 'Ustaw';
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToastDirective,
    DatetimepickerComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PlatformModule,
    NgxPermissionsModule.forRoot()
  ],
  exports: [
    PlatformModule
  ],
  providers: [AppInitializerService, ApiUsersService, UsersService, ApiRepairsService, RepairsService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitializerService],
      multi: true
    },
    MenuService,
    LoginService,
    AppInitService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'},
    {provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform]},
    {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

