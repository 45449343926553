import {GenericToolService} from './generic-tool-service';
import {Injectable} from '@angular/core';
import {MapService} from '../services/map-service';

@Injectable()
export class RoutingToolService extends GenericToolService {

  name = 'Routing';
  routableLayer: any;
  registerableFunc: any;

  activate(mapService: MapService) {
    this.mapService = mapService;
    this.map = this.mapService.map;

    this.routableLayer = this.getRoutableLayer(this.mapService.getAllLayers());

    if (this.routableLayer == null) {
      return;
    }

    //passing scope to singleclick not working in angular;
    const me = this;
    this.registerableFunc = function (evt) {
      me.executeGetFeatureInfo(evt);
    }

    this.map.on('singleclick', this.registerableFunc);

  }

  getRoutableLayer(layers) {

    for (const i in layers) {
      if (layers[i].layerConfig && layers[i].layerConfig.routingInfo) {
        return layers[i];
      }
    }
    return null;
  }

  replaceUrlParam (url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '';
    }
    const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
  }

  executeGetFeatureInfo (evt) {

    const url = this.generateGetFeatureInfoUrl(evt);

    this.mapHttpService.getFeatureInfo(url).subscribe(response => {

      let featureId = response.features[0].id;
      if (featureId.indexOf('.') > -1) {
        featureId = featureId.split('.')[1];
      }
      const routing = this.routableLayer.layerConfig.routingInfo;
      this._router.navigate(['/' + routing + '/details'], { queryParams: { id: featureId } });

    });
  }

  generateGetFeatureInfoUrl(evt) {

    const viewResolution = this.map.getView().getResolution();
    const layerName = this.routableLayer.getSource().getParams().LAYERS;

    const params = {
      'INFO_FORMAT' : 'application/json',
      'QUERY_LAYERS' : layerName,
      'LAYERS' : layerName,
      'FEATURE_COUNT' : 50
    }

    params['BUFFER'] = 20;

    return this.routableLayer.getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, this.mapService.getSrs(), params);

  }

  deactivate() {
    if (this.registerableFunc) {
      this.map.un('singleclick', this.registerableFunc);
    }
  }
}
