import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Injectable, Injector, ViewContainerRef } from '@angular/core';

@Injectable()
export class ToastService {
  private _matSnackBarConfig: MatSnackBarConfig;
  private _matSnackBar: MatSnackBar;
  private _matErrorSnackBarConfig: MatSnackBarConfig;

  constructor(
    private injector: Injector
  ) {
    this._matSnackBar = this.injector.get(MatSnackBar);
    this._matSnackBarConfig = {
      duration: 5000,
      direction: 'rtl',
      panelClass: ['CustomSnackBar'],
    };
    this._matErrorSnackBarConfig = {
      duration: 5000,
      direction: 'rtl',
      panelClass: ['CustomErrorSnackBar'],
    };
  }

  public success(text: string): void {
    this._matSnackBar.open(text, null, this._matSnackBarConfig);
  }

  public warning(text: string): void {
    this._matSnackBar.open(text, null, this._matSnackBarConfig);
  }

  public error(text: string): void {
    this._matSnackBar.open(text, null, this._matErrorSnackBarConfig);
  }

  public init(viewContainerRef: ViewContainerRef): void {
    this._matSnackBarConfig.viewContainerRef = viewContainerRef;
  }
}
