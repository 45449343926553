import { AppInitService } from './../../../../services/app-init.service';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ROUTES } from './../../../../configs/sidebar-routes.config';
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {
  isOpenTableSettings = false;
  availableModule;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elemReferenc.nativeElement.contains(event.target)) {
      this.isOpenTableSettings = false;
    }
  }
  constructor(private elemReferenc: ElementRef,
    private permissionsService: NgxPermissionsService, private _router: Router,
    private _appInitService: AppInitService) {
    this.availableModule = ROUTES;
  }

  ngOnInit() {
  }

  goToApplication(app) {
    const filteredRoutes = ROUTES.find((route) => route.code === app.code).children;
    filteredRoutes.forEach(element => {
      if (this.permissionsService.getPermission(element.permissions)) {
        this._router.navigateByUrl(app.path || element.path);
        this.isOpenTableSettings = false;
        localStorage.setItem('availableApplicationCode', app.code);
        localStorage.setItem('availableApplicationTitle', app.title);
        return;
      }
    });
    this._appInitService.changeApplication.next(app);
  }

  changeClosingComponentStatus() {
    this.isOpenTableSettings = !this.isOpenTableSettings;
  }

}
