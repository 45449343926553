import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastService } from 'src/app/common/services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _internalServerErrors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  private _badRequestErrors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  private _unauthorizedErrors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  private _forbiddenErrors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
  private _validationErrors: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  public internalServerErrors$: Observable<HttpErrorResponse> = this._internalServerErrors.asObservable();
  public badRequestErrors$: Observable<HttpErrorResponse> = this._badRequestErrors.asObservable();
  public unauthorizedErrors$: Observable<HttpErrorResponse> = this._unauthorizedErrors.asObservable();
  public forbiddenErrors$: Observable<HttpErrorResponse> = this._forbiddenErrors.asObservable();
  public validationErrors$: Observable<HttpErrorResponse> = this._validationErrors.asObservable();

  constructor(private _toastService: ToastService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {

          switch (response.status) {
            case 400:
              if (response.error) {
                const errorData = response.error['hydra:description'];
                this._toastService.error(errorData);
              }

              this._badRequestErrors.next(response);
              break;
            case 401:
              this._unauthorizedErrors.next(response);
              break;
            case 403:
              this._forbiddenErrors.next(response);
              break;
            case 500:
              this._internalServerErrors.next(response);
              break;
          }
        }

        return throwError(response);
      }));
  }
}
