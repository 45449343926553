import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './core/components/main-layout/main-layout.component';
import { LoginComponent } from './common/components/login/login.component';
import { AuthGuard } from './common/app-permission/auth.guard';
import { NoPermissionsComponent } from './core/components/no-permissions/no-permissions.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'no-permissions',
    component: NoPermissionsComponent
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'password',
        loadChildren: () => import('./common/components/password/password.module').then(m => m.PasswordModule)
      },
      {
        path: 'map',
        canActivate: [AuthGuard],
        loadChildren: () => import('./common/components/map/map.module').then(m => m.MapModule)
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'wod-kan',
        canActivate: [AuthGuard],
        loadChildren: () => import('./wod-kan/wod-kan.module').then(m => m.WodKanModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
