import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Injectable()
export class AppInitializerService {

  private _apiRoutesPrefix = `${environment.apiPrefix}/routes`;

  constructor(private _http: HttpClient, private permissionsService: NgxPermissionsService, private injector: Injector) {
  }

  init(): Promise<any> {
    const params = new HttpParams().set('pagination', 'false');
    if (localStorage.getItem('userToken') != null) {

      const promise = this._http.get(`${this._apiRoutesPrefix}/by-current-user`, { params: params }).pipe(
        map((response) => {
          const mapedResponse = response['hydra:member'];
          return mapedResponse;
        })
      ).toPromise()
        .then((model) => {
          this.permissionsService.loadPermissions(this.preparePermissions(model));
        }).catch((err) => {
          if (err.error.code === 401) {
            const router = this.injector.get(Router);
            localStorage.removeItem('userToken');
            localStorage.removeItem('userId');
            router.navigate(['/login']);
          }
        });
      return promise;
    }
  }

  preparePermissions(model: Array<any>) {
    const preparedArray = [];
    model.forEach((element: any) => {
      preparedArray.push(element.code);
    });
    return preparedArray;
  }


}


