import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class SpatialApiService {

  private _getObjectUrl = environment.apiPrefix + '/gis_layer_records';

  constructor(private _http: HttpClient) { }

  getSpatialObject(layer: string, id: number): Observable<any> {
    return this._http.get<any>(this._getObjectUrl + '/' + layer + '/' + id)
      .pipe(map(response => {
        //const jsonString = JSON.stringify(response.config.replace(/[\n]/g, '').replace(/'/g, '"'));
        //const data = JSON.parse(JSON.parse(jsonString));
        return response;
      }));
  }

}
