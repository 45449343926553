import {GenericToolService} from './generic-tool-service';
import {Modify, Snap} from 'ol/interaction.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {EventEmitter, Output} from '@angular/core';
import {MapService} from '../services/map-service';

export class EditGeometryToolService extends GenericToolService {

  @Output() geometryUpdated = new EventEmitter();

  properties: any;
  name = 'EditGeometry';
  layer: VectorLayer;
  modify: Modify;
  snap: Snap;

  activate(mapService: MapService, properties: any) {
    this.mapService = mapService;
    this.map = this.mapService.map;
    this.layer = this.mapService.editableLayer;
    this.properties = properties;

    const vectorSource = this.layer.getSource();

    this.modify = new Modify({
      source: vectorSource
    });

    const me = this;
    this.modify.on('modifyend', function(event) {
      const geometry = me.mapService.toGeojson(event.features.item(0).getGeometry().clone().transform('EPSG:3857', me.mapService.mapConfig.dataProjection));
      me.geometryUpdated.emit(geometry);
    });

    this.snap = new Snap({ source: vectorSource });

    this.map.addInteraction(this.modify);
    this.map.addInteraction(this.snap);

  }

  clearDrawing() {
    this.layer.getSource().clear();
    this.geometryUpdated.emit(null);
  }

  deactivate() {
    if (!this.properties.preserve) {
      this.map.removeLayer(this.layer);
    }

    this.map.removeInteraction(this.modify);
    this.map.removeInteraction(this.snap);
  }
}
