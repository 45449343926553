import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class MapEventBusService {

  @Output() drawingEvent = new EventEmitter();
  @Output() toolDeactivated = new EventEmitter();
  @Output() drawChanged = new EventEmitter();
  @Output() drawSaved = new EventEmitter();
  @Output() drawingSessionEnded = new EventEmitter();
  @Output() selectEditableLayer = new EventEmitter();
  @Output() changeLayerCancelled = new EventEmitter();
  @Output() infoSelected = new EventEmitter();
  @Output() infoUnselected = new EventEmitter();
  @Output() objectAssignSelected = new EventEmitter();
  @Output() objectAssignUnselected = new EventEmitter();

  constructor() {}

}
