import { Injectable } from '@angular/core';
import { ApiUsersService } from './users-api.service';
import { EntriesItem } from '../models/users.model';
import { DatasetClass } from 'src/app/common/dataset/dataset.class';

@Injectable()
export class UsersService extends DatasetClass<EntriesItem> {
  constructor(private _apiUsersService: ApiUsersService) {
    super(_apiUsersService
      );
  }
}
