import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';

@Injectable()
export class LegendToolService extends GenericToolService {

  @Output() legendUrlCalculated = new EventEmitter();

  name = 'Legend';

  activate(mapService: MapService) {

     this.mapService = mapService;
     this.map = this.mapService.map;

     const legendLayers = this.mapService.getLayersByProperty('legend', true);
     if (!legendLayers || legendLayers.length === 0) {
       console.error('No layer marked as legend layer. Mark at least one layer with legend=true in map configuration.');
       this.legendUrlCalculated.emit(undefined);
     } else {
       const url = this.buildGetLegendUrl(legendLayers[0]);
       this.legendUrlCalculated.emit(url);
     }
  }

  buildGetLegendUrl(layer: any): string {

    const cfg = layer.layerConfig;
    return cfg.url + '?' +
      'REQUEST=GetLegendGraphic' + '&' +
      'FORMAT=image/png' + '&' +
      'LAYER=' + cfg.layerName + '&' +
      'width=32&height=32';
  }

}
