import { Subscription, Subject } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AppInitService {
  private _apiUserPrefix = `${environment.apiPrefix}/users`;
  private _apiRoutesPrefix = `${environment.apiPrefix}/routes`;
  userData;

  invokeFirstComponentFunction = new EventEmitter();
  invokeFirstComponentSearch = new EventEmitter();
  subsVar: Subscription;
  subsGlobalInput: Subscription;
  changeApplication = new Subject();
  constructor(private _http: HttpClient) {
  }


  globalSearch(event) {
    this.invokeFirstComponentSearch.emit(event);
  }

  emitOpenFilters() {
    this.invokeFirstComponentFunction.emit();
  }

  emitCloseFilters() {
    this.invokeFirstComponentFunction.emit();
  }


  getUserDetails(id) {
    return this._http.get(`${this._apiUserPrefix}/${id}`);
  }

  getUserPermissions() {
    const params = new HttpParams().set('pagination', 'false');

    return this._http.get(`${this._apiRoutesPrefix}/by-current-user`, { params: params }).pipe(
      map((response) => {
        const mapedResponse = response['hydra:member'];
        return mapedResponse;
      })
    );
  }


}
