import {Component, EventEmitter, Injectable, Output} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable, BehaviorSubject} from 'rxjs';

export class LayerTreeNode {
  children: LayerTreeNode[];
  item: string;
  layerName: string;
  visible: boolean;
}

export class LayerTreeFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  layerName: string;
  visible: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange: BehaviorSubject<LayerTreeNode[]> = new BehaviorSubject<LayerTreeNode[]>([]);

  get data(): LayerTreeNode[] { return this.dataChange.value; }

  constructor() {}

  loadData(data: any) {
    const treeData = this.buildTree(data, 0);
    this.dataChange.next(treeData);
  }

  buildTree(data: any, level: number) {
    let result: any[] = [];
    for (let i in data) {
      let node = new LayerTreeNode();
      node.item = `${data[i].name}`;
      node.visible = data[i].visible || false;
      if (data[i].type === 'group') {
        node.children = this.buildTree(data[i].layers, level+1);
      }else{
        node.layerName = data[i].layerName;
      }
      result.push(node);
    }

    return result;
  }

  insertItem(parent: LayerTreeNode, name: string) {
    const child = <LayerTreeNode>{item: name};
    if (parent.children) {
      parent.children.push(child);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: LayerTreeNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-layer-tree',
  templateUrl: './layer-tree.component.html',
  styleUrls: ['./layer-tree.component.scss'],
  providers: [ChecklistDatabase]
})
export class LayerTreeComponent {

  @Output() layerVisibilityChanged = new EventEmitter();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap: Map<LayerTreeFlatNode, LayerTreeNode> = new Map<LayerTreeFlatNode, LayerTreeNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<LayerTreeNode, LayerTreeFlatNode> = new Map<LayerTreeNode, LayerTreeFlatNode>();

  treeControl: FlatTreeControl<LayerTreeFlatNode>;

  treeFlattener: MatTreeFlattener<LayerTreeNode, LayerTreeFlatNode>;

  dataSource: MatTreeFlatDataSource<LayerTreeNode, LayerTreeFlatNode>;

  checklistSelection = new SelectionModel<LayerTreeFlatNode>(true /* multiple */);

  constructor(private database: ChecklistDatabase) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<LayerTreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    this.checklistSelection.changed.asObservable().subscribe(selectionchange => {
      selectionchange.added.forEach(node => {
        this.layerVisibilityChanged.emit({layerName:node.layerName, visible: true});
      });

      selectionchange.removed.forEach(node => {
        this.layerVisibilityChanged.emit({layerName:node.layerName, visible: false});
      });
    });
  }

  getLevel = (node: LayerTreeFlatNode) => { return node.level; };

  isExpandable = (node: LayerTreeFlatNode) => { return node.expandable; };

  getChildren = (node: LayerTreeNode): Observable<LayerTreeNode[]> => {
    return ofObservable(node.children);
  }

  hasChild = (_: number, _nodeData: LayerTreeFlatNode) => { return _nodeData.expandable; };

  loadData(data: any) {
    this.database.loadData(data);
  }

  transformer = (node: LayerTreeNode, level: number) => {
    let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item
      ? this.nestedNodeMap.get(node)!
      : new LayerTreeFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.layerName = node.layerName;
    flatNode.visible = node.visible;
    if (flatNode.visible) {
      this.checklistSelection.select(flatNode);
    }

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  descendantsAllSelected(node: LayerTreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: LayerTreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  nodeSelectionToggle(node: LayerTreeFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }
}
