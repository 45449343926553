import { ISidebarRoutes } from '../models/sidebar.model';

export const ROUTES: ISidebarRoutes[] = [
  {
    title: 'Wod-Kan',
    code: 'wod-kan',
    permissions: [],
    path: '/wod-kan/advanced-map',
    children: [
      {
        path: '/wod-kan/advanced-map',
        title: 'Mapa',
        permissions: '',
        children: []
      },
      {
        title: 'Dyspozytornia',
        code: 'dyspozytornia',
        permissions: [],
        path: '/wod-kan/accident/list',
        children: [
          {
            path: '/wod-kan/accident/list',
            title: 'Awarie',
            permissions: 'api_awarias_get_collection',
            children: []
          },
          {
            path: '/wod-kan/repairs/list',
            title: 'Remonty',
            permissions: 'api_remonts_get_collection',
            children: []
          },
          {
            path: '/wod-kan/investments/list',
            title: 'Inwestycje',
            permissions: '',
            children: []
          },
          {
            path: '/wod-kan/commissions/list',
            title: 'Zlecenia',
            permissions: '',
            children: []
          },
          {
            path: '/wod-kan/maintenances/list',
            title: 'Konserwacje',
            permissions: '',
            children: []
          },
          {
            path: '/wod-kan/inspections/list',
            title: 'Przeglądy',
            permissions: '',
            children: []
          }
        ]
      },
      {
        path: '/wod-kan/documents/list',
        title: 'Dokumenty',
        permissions: 'api_attachments_get_collection',
        children: []
      },
      {
        path: '/wod-kan/application/list',
        title: 'Zgłoszenia',
        permissions: 'api_zgloszenies_get_collection',
        children: []
      },
      {
        path: '/wod-kan/hydrants-review/list',
        title: 'Przegląd hydrantów',
        permissions: 'api_przeglad_hydrantus_get_collection',
        children: []
      },
      {
        path: '/wod-kan/video-inspections/list',
        title: 'Inspekcje video',
        permissions: 'api_inspekcje_videos_get_collection',
        children: []
      },
      {
        path: '/wod-kan/transmission-easement/list',
        title: 'Służebności przesyłu',
        permissions: 'api_sluzebnosc_przesylus_get_collection',
        children: []
      },
      {
        path: '/wod-kan/analysis/main',
        title: 'Analizy i raporty',
        permissions: '',
        children: []
      },
      {
        title: 'Administracja',
        code: 'administracja',
        permissions: [],
        path: '/administration/users',
        children: [
          {
            title: 'Użytkownicy',
            path: '/administration/users',
            permissions: 'api_users_get_collection'
          },
          {
            title: 'Uprawnienia',
            path: '/administration/permissions',
            permissions: 'api_roles_get_collection'
          },
          {
            title: 'Struktura organizacyjna',
            path: '/administration/organization-structure',
            permissions: ''
          }
        ]
      },
    ]
  }
  // {
  //   path: '/map',
  //   title: 'Mapa',
  //   code: '',
  //   permissions: '',
  //   children: []
  // }
];
