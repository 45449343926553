import { GenericToolService } from './generic-tool-service';
import { Draw } from 'ol/interaction.js';
import { LineString, Polygon } from 'ol/geom.js';
import Overlay from 'ol/Overlay.js';
import Feature from 'ol/Feature.js';
import { getArea, getLength } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {Injectable} from '@angular/core';
import { Vector as VectorSource } from 'ol/source';
import {StyleFactoryService} from '../services/style-factory-service';
import Map from 'ol/Map.js';
import {MapService} from '../services/map-service';

@Injectable()
export class MeasureToolService extends GenericToolService {

  name = 'Measure';
  vectorLayer: VectorLayer;
  measure: Draw;
  measureTooltipElement: Element;
  measureTooltip: Overlay;
  sketch: Feature;
  listener: any;
  styleFactory: StyleFactoryService;

  activate(mapService: MapService, selectedType: string) {
    this.mapService = mapService;
    this.map = this.mapService.map;

    if (!this.vectorLayer) {
      this.vectorLayer = new VectorLayer({
        name: 'Pomiary',
        source: new VectorSource({}),
        style: this.styleFactory.createFinishedMeasureStyle()
      });
      this.map.addLayer(this.vectorLayer);
    }

    this.measure = new Draw({
      source: this.vectorLayer.getSource(),
      type: selectedType === 'LENGTH' ? 'LineString' : 'Polygon',
      style: this.styleFactory.createMeasuringStyle()
    });

    this.map.addInteraction(this.measure);
    const me = this;

    this.measure.on('drawstart', function (evt) {
      me.sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      me.listener = me.sketch.getGeometry().on('change', function (event) {
        const geom = event.target;
        let output;
        if (geom instanceof Polygon) {
          output = me.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = me.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        me.measureTooltipElement.innerHTML = output;
        me.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.measure.on('drawend', function () {
      me.measureTooltipElement.className = 'tooltip tooltip-static';
      me.measureTooltip.setOffset([0, -7]);
      me.sketch = null;
      me.measureTooltipElement = null;
      me.createMeasureTooltip();
      unByKey(me.listener);
    });

    this.createMeasureTooltip();

  }

  changeMeasureType(map: Map, measureType: string) {
    this.deactivate();
    this.activate(map, measureType);
  }

  deactivate() {
    this.vectorLayer.getSource().clear();
    this.map.getOverlays().clear();
    this.map.removeInteraction(this.measure);
  }

  clearMeasurements() {
    this.vectorLayer.getSource().clear();
    this.map.getOverlays().clear();
    this.createMeasureTooltip();
  }

  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';

    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });
    this.map.addOverlay(this.measureTooltip);
  }


  formatLength(line: LineString) {
    const length = getLength(line);
    if (length > 1000) {
      return (Math.round((length + 0.00001) * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ') + ' ' + 'm';
    } else {
      return Math.round((length + 0.00001) * 100) / 100 + ' ' + 'm';
    }
  }

  formatArea(polygon: Polygon) {
    const area = getArea(polygon);
    return Math.round(area / 10000 * 10000) / 10000 + ' ' + 'ha';
  }
}
