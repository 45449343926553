import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';
import {MapEventBusService} from '../services/map/map-event-bus-service';
import {MapConfigService} from '../services/map-config-servce';
import {StyleFactoryService} from '../services/style-factory-service';
import {MapHttpService} from '../services/map-http-service';
import {Router} from '@angular/router';

@Injectable()
export class ObjectAssignToolService extends GenericToolService {

  @Output() objectAssignFeatureInfoRetrieved = new EventEmitter();

  tempLayer: any;
  name = 'ObjectAssign';
  clickableLayers: any[];
  registerableFunc: any;

  constructor( private eventBus: MapEventBusService,
               mapConfigService: MapConfigService, styleFactory: StyleFactoryService, mapHttpService: MapHttpService, _router: Router) {
    super(mapConfigService, styleFactory, mapHttpService, _router);
  }

  activate(mapService: MapService) {
    this.mapService = mapService;
    this.map = this.mapService.map;

    this.tempLayer = this.mapService.addTemporaryLayerWithStyle('Info', this.styleFactory.createPolygonStyle());
    this.tempLayer.layerConfig.printable = false;

    this.clickableLayers = this.getClickableLayers(this.mapService.getAllLayers());

    if (this.clickableLayers == null || this.clickableLayers.length === 0) {
      return;
    }

    //passing scope to singleclick not working in angular;
    const me = this;
    this.registerableFunc = function (evt) {
      me.executeGetFeatureInfo(evt);
    }

    this.map.on('singleclick', this.registerableFunc);

    this.eventBus.objectAssignSelected.subscribe((feature) => {
      this.mapService.addSingleData(this.tempLayer, feature.geometry, false,  false);
    });

    this.eventBus.objectAssignUnselected.subscribe(() => {
      this.tempLayer.getSource().clear();
    });

  }

  getClickableLayers(layers) {

    const resultLayers = [];

    for (const i in layers) {
       if (layers[i].layerConfig && layers[i].layerConfig.routingInfo) {
         resultLayers.push(layers[i]);
       }
    }

    return resultLayers;
  }

  executeGetFeatureInfo (evt) {

    const url = this.generateGetFeatureInfoUrl(evt);

    this.mapHttpService.getFeatureInfo(url).subscribe(response => {
      this.objectAssignFeatureInfoRetrieved.emit(response.features);
    });
  }

  generateGetFeatureInfoUrl(evt) {
    return this.mapService.generateGetFeatureInfoUrl(evt, this.getLayerNames(), this.clickableLayers[0]);
  }

  getLayerNames() {
    let first = true;
    let names = '';
    for (const i in this.clickableLayers) {
      if (first) {
        names += this.clickableLayers[i].layerConfig.layerName;
        first = false;
      } else {
        names += ',' + this.clickableLayers[i].layerConfig.layerName;
      }
    }

    return names;
  }

  deactivate() {
    if (this.registerableFunc) {
      this.map.un('singleclick', this.registerableFunc);
    }

    this.map.removeLayer(this.tempLayer);
    this.tempLayer = undefined;
  }
}
