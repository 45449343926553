import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {MatPaginator, MatSort} from '@angular/material';
import { Observable } from 'rxjs';
import { timer, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';

interface IDatasetClass<T> {
  // paginator$: BehaviorSubject<MatPaginator>;
  // filters$: BehaviorSubject<any>;
  dataSource$: BehaviorSubject<T[]>;
  getList(): Observable<T[]>;
}
@Injectable()

export class DatasetClass<T> implements IDatasetClass<T> {
  private _init = {
    paginator: <MatPaginator>{
      pageSize: 10,
      pageIndex: 0,
      length: 0
    },
    sort: <MatSort>{
      direction: 'desc'
    },
    filters: {}
  };
  paginator$;
  sort$;
  filters$;
  dataSource$ = new BehaviorSubject<any>({});

  constructor(private _apiService) {
    this.paginator$ = new BehaviorSubject<MatPaginator>(this._init.paginator);
    this.filters$ = new BehaviorSubject<any>({ ...this._init.filters });
    this.sort$ = new BehaviorSubject<any>({ ...this._init.sort });

    combineLatest(
      this.paginator$,
      this.sort$,
      this.filters$
    )

      .subscribe(([paginator, sort, filters]: any) => {
        this.getList(paginator, sort, filters).pipe(
          first()
        ).subscribe();
      });
  }

  getList(paginator = this._init.paginator,
    sort = this._init.sort,
    filters = this._init.filters) {
    return this._apiService.getList(paginator, sort, filters).pipe(
      map(response => {

        const typedResponse = response;
        this.dataSource$.next(typedResponse);
        // this.paginator$.next(response['hydra:totalItems']);
        return typedResponse;
      })
    );
  }
}
