import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SnappingConfig } from '../../models/snapping-config.model';
import {MapService} from '../../services/map-service';

@Component({
  selector: 'app-snapping-panel',
  templateUrl: './snapping-panel.component.html',
  styleUrls: ['./snapping-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnappingPanelComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Output() snappingConfigChanged = new EventEmitter();

  public selectedMode = 'EDITED_LAYER';

  snappingConfig: SnappingConfig;
  selectedConfig: SnappingConfig;
  vectorLayers: string[] = [];

  constructor(private changeDetection: ChangeDetectorRef, private mapService: MapService) {
    this.snappingConfig = new SnappingConfig();
    this.snappingConfig.mode = 'EDITED_LAYER';
    this.snappingConfig.snapTo = 'VERTEX';
    this.snappingConfig.tolerance = 10;
    this.snappingConfig.selectedLayers = [];
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.mapService.wfsLayerAdded.subscribe(newLayer => {
      this.vectorLayers.push(newLayer.layerConfig.name);
      this.changeDetection.markForCheck();
      this.changeDetection.detectChanges();
    });
  }

  getSnappingConfig(): SnappingConfig {
    return this.snappingConfig;
  }

  setVectorLayers(selectedlayers: string[], vectorLayers: string[]) {
    this.snappingConfig.selectedLayers = selectedlayers;
    this.vectorLayers = vectorLayers;
  }

  applySnapping() {
    this.snappingConfigChanged.emit(this.getSnappingConfig());
  }
}
