import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class MapHttpService {

  private apiInfoUrl = environment.apiPrefix + '/get-feature-info';

  constructor(private _http: HttpClient) { }

  getFeatureInfo(url: string): Observable<any> {
    return this._http.get<any>(url);
  }

  getInfo(request: any): Observable<any> {
    return this._http.post<any>(this.apiInfoUrl, request);
  }

  executeGetApiRequest(url: string): Observable<any> {
    return this._http.get<any>(environment.apiPrefix + url);
  }

}
