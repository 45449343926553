import { GenericToolService } from './generic-tool-service';
import Feature from 'ol/Feature.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point.js';
import Geolocation from 'ol/Geolocation.js';
import Map from 'ol/Map.js';

export class GeolocationToolService extends GenericToolService {

  name = 'Geolocation';
  vectorLayer: VectorLayer;
  geolocation: Geolocation;

  activate(map: Map) {

    this.map = map;

    this.isActive = true;

    if (!this.vectorLayer) {
      this.vectorLayer = new VectorLayer({
        name: 'Geolokalizacja',
        source: new VectorSource({})
      });
      this.map.addLayer(this.vectorLayer);
    }

    if (!this.geolocation) {
      this.geolocation = new Geolocation({
        trackingOptions: {
          enableHighAccuracy: true
        }
      });

      const me = this;
      this.geolocation.on('change:position', function() {
        me.updatePosition(me.geolocation.getPosition());
      });
    }

    this.geolocation.setTracking(true);
  }

  deactivate() {

    this.isActive = false;
    this.geolocation.setTracking(false);
    this.vectorLayer.getSource().clear();
    this.map.removeLayer(this.vectorLayer);

    delete this.vectorLayer;
    delete this.geolocation;
  }

  updatePosition(coordinates) {
    const geometry = new Point(coordinates);
    geometry.transform('EPSG:4326', 'EPSG:3857');
    const positionFeature = new Feature();
    positionFeature.setGeometry(geometry);
    positionFeature.setStyle(this.styleFactory.createPointStyle());
    this.vectorLayer.getSource().clear();
    this.vectorLayer.getSource().addFeature(positionFeature);
    this.map.getView().fit(geometry, {size: this.map.getSize()});
    this.map.getView().setZoom(16);
  }
}
