import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';

@Injectable()
export class MenuService {
  public availableMenu: Routes = [];
  constructor() { }

  public initMenu() {
    return this.availableMenu;
  }
}
