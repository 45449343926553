
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem('userToken') != null && req.url.includes((environment.apiPrefix))) {
      const cloneDreq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('userToken'))
      });

      return next.handle(cloneDreq).pipe(
        tap(succ => { },
          err => {
            if (err.status === 401) {
              localStorage.removeItem('userToken');
              localStorage.removeItem('userId');
              this._router.navigateByUrl('/login');
            }
          })
      );
    } else {
      return next.handle(req.clone());
    }
  }
}
