import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISelectModel } from '../models/repairs.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiRepairsService {
  private _apiPrefix = `${environment.apiPrefix}`;

  constructor(private _http: HttpClient) { }

  getList(pagination: any, sort?, filters?) {
    let httpParams = new HttpParams().set('page', pagination.pageIndex + 1).set('itemsPerPage', pagination.pageSize);

    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          httpParams = httpParams.append(key, filters[key]);
        }
      });
    }
    if (sort) {
      httpParams = httpParams.append(sort.active, sort.direction);
    }
    return this._http.get(`${this._apiPrefix}/remonts`, { params: httpParams });
  }

  exportList(filters?) {
    let filteredFilters = {};
    if (filters) {
      filters.subscribe((model) => {
        filteredFilters = model;
      });
    }

    let params = new HttpParams().set('pagination', 'false');
    if (filteredFilters) {
      Object.keys(filteredFilters).forEach(key => {
        if (filteredFilters[key]) {
          params = params.append(key, filteredFilters[key]);
        }
      });
    }

    const reqHeader = new HttpHeaders({ 'accept': 'application/vnd.ms-excel' });
    return this._http.get(`${this._apiPrefix}/remonts`, { params: params, headers: reqHeader, responseType: 'blob', observe: 'response' });
  }

  getListItem(type) {
    const params = new HttpParams().set('page', '1').set('itemsPerPage', '100');
    return this._http.get(`${this._apiPrefix}/${type}`, { params: params });
  }

  getModelForSelect(type: string): Observable<ISelectModel[]> {
    return this._http.get<ISelectModel[]>(`${this._apiPrefix}/${type}`).pipe(
      map((response) => {
        const mapedResponse = response['hydra:member'];
        return mapedResponse;
      })
    );
  }

  edit(model, id) {
    return this._http.put(`${this._apiPrefix}/remonts/${id}`, model);
  }

  createNew(model) {
    return this._http.post(`${this._apiPrefix}/remonts`, model);
  }

  connectObject(model) {
    return this._http.post(`${this._apiPrefix}/remont_gesuts`, model);
  }
  deleteConnectedObject(id) {
    const params = new HttpParams().set('id', id);
    return this._http.delete(`${this._apiPrefix}/remont_gesuts/${id}`);
  }
  getConnectedObjects() {
    return this._http.get(`${this._apiPrefix}/remont_gesuts`);
  }

  getDetails(id) {
    return this._http.get(`${this._apiPrefix}/remonts/${id}`);
  }

  removeItem(id) {
    return this._http.delete(`${this._apiPrefix}/remonts/${id}`);
  }

  uploadFile(model) {
    const formData = new FormData();
    const req = new HttpRequest('POST', `${this._apiPrefix}/attachments`, formData, {
      reportProgress: true
    });
    formData.append('objectClass', model.objectClass);
    formData.append('type', model.type);
    formData.append('name', model.name);
    if (model.notices) {
      formData.append('notices', model.notices);
    }
    formData.append('objectId', model.objectId);
    formData.append('file', model.file, model.file.name);

    return this._http.request(req);
  }

  removeFile(id) {
    return this._http.delete(`${this._apiPrefix}/attachments/${id}`);
  }

  getFileDetails(id) {
    return this._http.get(`${this._apiPrefix}/attachments`);
  }

  getFileListWithParams(param, value, objectClass?) {
    const params = new HttpParams().set(param, value).set('objectClass', objectClass);
    return this._http.get(`${this._apiPrefix}/attachments`, { params: params });
  }
  getObjectListWithParams(param, value, objectClass?) {
    const params = new HttpParams().set(param, value).set('objectClass', objectClass);
    return this._http.get(`${this._apiPrefix}/remont_gesuts`, { params: params });
  }

  downloadAttachment(id) {
    return this._http.get(`${this._apiPrefix}/attachments/${id}/download`, { responseType: 'arraybuffer' });
  }
}
