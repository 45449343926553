import { FormControl, FormGroup, Validators } from '@angular/forms';

export class LoginForm extends FormGroup {
  constructor() {
    super({
      login: new FormControl(null),
      password: new FormControl(null),
      remindMe: new FormControl(null)
    });
  }

  get login() {
    return this.get('login');
  }
  get password() {
    return this.get('password');
  }

  get remindMe() {
    return this.get('remindMe');
  }
}
