import { environment } from './../../../../environments/environment';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppInitService } from '../../services/app-init.service';
import { skip, takeUntil, debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  smallScreenQuery: MediaQueryList;
  opened = false;
  // TODO: remove when the login functionality would be.
  activeUser: string;
  activeUserInitial: string;
  searchInputFocused = false;
  openedAdvansedFilters = false;
  localStorage = localStorage;
  appName;
  private _unsubscribe = new Subject();


  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);
  globalSearch = new FormControl('');
  private _mobileQueryListener: () => void;
  constructor(
    private _media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _appInitService: AppInitService
  ) {
    this.mobileQuery = this._media.matchMedia('(max-width: 600px)');
    this.smallScreenQuery = this._media.matchMedia('(max-width: 1280px)');

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (this.localStorage.getItem('availableApplicationTitle')) {
      this.appName = this.localStorage.getItem('availableApplicationTitle');
    } else {
      this.appName = environment.nameApplication;
    }
    this._appInitService.changeApplication
      .subscribe((item: any) => {
        this.appName = item.title;
      });
    this.globalSearch.valueChanges.pipe(
      takeUntil(this._unsubscribe),
      debounceTime(500)
    ).subscribe((value) => {
      if (!this.openedAdvansedFilters) {
        this._appInitService.globalSearch(value);
      }
    });
  }

  ngOnInit() {
    // TODO: uncomment when the login functionality would be.
    // this.activeUser = sessionStorage.getItem('activeUser');
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  toggle() {
    this.opened = !this.opened;
  }

  private _onMediaChange(matches: boolean) {
    if (matches) {
      this.opened = false;
    } else {
      this.opened = true;
    }
  }

  openAdvancedFilters() {
    this.globalSearch.setValue('');
    if (!this.openedAdvansedFilters) {
      this._appInitService.emitOpenFilters();
      this.openedAdvansedFilters = true;
    } else {
      this._appInitService.emitCloseFilters();
      this.openedAdvansedFilters = false;
    }
  }

  prepareInitial(type) {
    if (localStorage.getItem('userData')) {
      const localStorageUserDate = JSON.parse(localStorage.getItem('userData'));
      const name = localStorageUserDate.firstName.charAt(0);
      const surname = localStorageUserDate.lastName.charAt(0);
      const initialName = (name + surname).toUpperCase();
      if (type === 'initial') {
        return initialName;
      } else if (type === 'jobPosition') {
        return `${localStorageUserDate.jobPosition}`;
      } else {
        return `${localStorageUserDate.firstName} ${localStorageUserDate.lastName}`;
      }
    }

  }

}
