import {Draw, Snap} from 'ol/interaction.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';
import {StyleFactoryService} from '../services/style-factory-service';
import Map from 'ol/Map.js';
import {GenericToolService} from './generic-tool-service';

@Injectable()
export class AddGeometryToolService extends GenericToolService {

  @Output() geometryAdded = new EventEmitter();

  properties: any;
  mapService: MapService;
  styleFactory: StyleFactoryService;

  map: Map;

  name = 'AddGeometry';
  vectorLayer: VectorLayer;
  snappingLayer: VectorLayer;
  draw: Draw;
  snap: Snap;

  activate(mapService: MapService, properties: any) {
    this.mapService = mapService;
    this.properties = properties || {};
    this.map = mapService.map;

    if (!this.vectorLayer) {
      this.vectorLayer = this.mapService.addTemporaryLayer('Nowy obiekt');

      const me = this;
      this.vectorLayer.getSource().on('addfeature', function(event) {
        const geometry = me.mapService.toGeojson(event.feature.getGeometry().clone().transform('EPSG:3857', mapService.mapConfig.dataProjection));
        me.geometryAdded.emit(geometry);
      });
    }

    const vectorSource = this.vectorLayer.getSource();

    this.draw = new Draw({
      source: vectorSource,
      type: this.properties.type,
      style: this.styleFactory.createDrawStyle(this.properties.type)
    });

    if (this.properties.single) {
      this.draw.on('drawstart', function() {
        vectorSource.clear();
      });
    }

    this.map.addInteraction(this.draw);

    // if (snappingConfig) {
    //   this.snap = new Snap(snappingConfig);
    //   this.map.addInteraction(this.snap);
    // }
  }

  clearDrawing() {
    this.vectorLayer.getSource().clear();
    this.geometryAdded.emit(null);
  }

  deactivate() {

    if (!this.properties.preserve) {
      this.map.removeLayer(this.vectorLayer);
    }

    this.map.removeInteraction(this.draw);

    if (this.snap) {
      this.map.removeInteraction(this.snap);
      delete this.snap;
    }

    if (this.snappingLayer) {
      this.map.removeLayer(this.snappingLayer);
    }
  }
}
