import {MapService} from '../services/map-service';
import {StyleFactoryService} from '../services/style-factory-service';
import Map from 'ol/Map.js';
import {MapConfigService} from '../services/map-config-servce';
import {MapHttpService} from '../services/map-http-service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class GenericToolService {

  name: string;
  isActive = false;
  mapConfigService: MapConfigService;
  mapService: MapService;
  styleFactory: StyleFactoryService;
  mapHttpService: MapHttpService;
  interaction: any;
  map: Map;
  _http: HttpClient;
  protected _router: Router;

  constructor(mapConfigService: MapConfigService, styleFactory: StyleFactoryService, mapHttpService: MapHttpService, _router: Router) {
    this.mapConfigService = mapConfigService;
    this.styleFactory = styleFactory;
    this.mapHttpService = mapHttpService;
    this._router = _router;
  }

  deactivate() {
    if (this.interaction) {
      this.map.removeInteraction(this.interaction);
    }
  }

}
