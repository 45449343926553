import { environment } from 'src/environments/environment';
import { ROUTES } from '../../../core/configs/sidebar-routes.config';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastService } from '../../services/toast.service';
import { Component, OnInit } from '@angular/core';
import { LoginForm } from './login.form';
import { LoginService } from './services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppInitService } from '../../../core/services/app-init.service';

import { Subject } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: LoginForm;
  isLoginError: boolean;

  loginImage = environment.loginBackground;
  loginTitle = environment.loginTitle;
  applicationVersion = environment.applicationVersion;
  applicationAuthor = environment.applicationAuthor;
  appName = environment.appName;
  baseRoute = environment.baseRoute;
  private _unsubscribe = new Subject();

  constructor(private _loginService: LoginService, private _router: Router, private _appInit: AppInitService, private _toastService: ToastService,
    private permissionsService: NgxPermissionsService) {
    this.loginForm = new LoginForm();
  }

  ngOnInit() {
    this.redirectToAvailableModule();
  }

  preparePermissions(model: Array<any>) {
    const preparedArray = [];
    model.forEach((element: any) => {
      preparedArray.push(element.code);
    });
    return preparedArray;
  }

  redirectToAvailableModule() {
    if (localStorage.getItem('userToken') != null) {
      if (localStorage.getItem('availableApplicationCode')) {
        this._appInit.getUserPermissions().subscribe((permissions) => {
          this.permissionsService.loadPermissions(this.preparePermissions(permissions));
          const filteredRoutes = ROUTES.find((route) => route.code === localStorage.getItem('availableApplicationCode')).children;
          filteredRoutes.forEach(element => {
            if (this.permissionsService.getPermission(element.permissions)) {
              this._router.navigateByUrl(element.path);
              return;
            }
          });
        });

      } else {
        this._appInit.getUserPermissions().subscribe((permissions) => {
          this.permissionsService.loadPermissions(this.preparePermissions(permissions));
          this._router.navigate([this.baseRoute]);
        });
      }
    }
  }

  setBackground() {
    const styles = {
      'background': `url(${this.loginImage}) no-repeat`,
      'background-size': `cover`,
    };
    return styles;
  }

  onSubmit() {
    this._loginService.login(this.loginForm.value).subscribe((model: any) => {
      localStorage.setItem('userId', model.data.id);
      localStorage.setItem('userToken', model.token);
      this._appInit.getUserDetails(model.data.id).subscribe((model) => {
        localStorage.setItem('userData', JSON.stringify(model));
        this.redirectToAvailableModule();
      });
    }, (err: HttpErrorResponse) => {
      this.isLoginError = true;
      this._toastService.error('Nieprawidłowy identyfikator lub hasło');
    });
  }

}
