import {EventEmitter, Injectable, Output} from '@angular/core';
import {SnappingConfig} from '../../models/snapping-config.model';
import {MapService} from '../map-service';
import {Snap} from 'ol/interaction';
import Collection from 'ol/Collection';

@Injectable()
export class SnappingManagerService {

  @Output() configUpdated = new EventEmitter();
  @Output() layersChanged = new EventEmitter();

  mapService: MapService;
  snappingConfig: SnappingConfig;
  editedLayer: string;

  snap: Snap;

  constructor(mapService: MapService) {
    this.snappingConfig = new SnappingConfig();
    this.snappingConfig.mode = 'EDITED_LAYER';
    this.snappingConfig.snapTo = 'VERTEX';
    this.snappingConfig.tolerance = 10;
    this.snappingConfig.selectedLayers = [];

    this.mapService = mapService;
  }

  updateSnappingParams(snappingConfig: SnappingConfig) {
   this.snappingConfig = snappingConfig;
   this.updateSnapping();
  }

  updateSnappingLayer(editedLayer: string) {
    this.editedLayer = editedLayer;
    this.updateSnapping();
  }

  updateSnapping() {
    if (this.snap) {
      this.mapService.map.removeInteraction(this.snap);
    }

    this.snap = new Snap(this.parseSnappingConfig(this.snappingConfig, this.editedLayer));
    this.mapService.map.addInteraction(this.snap);
  }
  getSnappingConfig(): SnappingConfig {
    return this.snappingConfig;
  }

  parseSnappingConfig(snappingConfig: SnappingConfig, editableLayer: string): any {
    if (snappingConfig.mode === 'NONE') {
      return null;
    }
    return this.toSnapInteractionConfig(snappingConfig, editableLayer);
  }

  toSnapInteractionConfig(snappingConfig: SnappingConfig, editableLayer: string): any {

    const features = this.getSnappingFeatures(snappingConfig, editableLayer);
    if (!features || features.getLength() === 0) {
      return null;
    }

    const cfg = {};
    cfg['features'] = features;

    if (snappingConfig.snapTo === 'VERTEX') {
      cfg['edge'] = false;
      cfg['vertex'] = true;
    } else if (snappingConfig.snapTo === 'EDGE') {
      cfg['edge'] = true;
      cfg['vertex'] = false;
    } else if (snappingConfig.snapTo === 'VERTEX_AND_EDGE') {
      cfg['edge'] = true;
      cfg['vertex'] = true;
    } else {
      cfg['edge'] = false;
      cfg['vertex'] = false;
    }

    cfg['pixelTolerance'] = snappingConfig.tolerance;

    return cfg;
  }

  getSnappingFeatures(cfg: SnappingConfig, editableLayer: string): Collection {
    let features: Collection;
    if (cfg.mode === 'EDITED_LAYER') {
      features = this.mapService.getFeatures(this.mapService.getLayersByProperties(
        [{property: 'layerName', value: editableLayer}, {property: 'type', value: 'wfs'}]));
    } else if (cfg.mode === 'ALL_LAYERS') {
      features = this.mapService.getFeatures(this.mapService.getLayersByProperty('type', 'wfs'));
    } else if (cfg.mode === 'SELECTED_LAYERS' && cfg.selectedLayers && cfg.selectedLayers.length > 0) {
      const selectedVectorLayers = [];
      for (const selectedLayer of cfg.selectedLayers) {
        selectedVectorLayers.push(this.mapService.getLayerByProperty('name', selectedLayer));
      }
      features = this.mapService.getFeatures(selectedVectorLayers);
    }

    return features;

  }
}
