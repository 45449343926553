import { GenericToolService } from './generic-tool-service';
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import { get as getProjection, transform, transformExtent} from 'ol/proj.js';
import {getCenter} from 'ol/extent.js';
import GeometryLayout from 'ol/geom/GeometryLayout';
import Translate from 'ol/interaction/Translate.js';
import Collection from 'ol/Collection.js';
import {MapService} from '../services/map-service';
import {EventEmitter, Output} from '@angular/core';

export class GraveToolService extends GenericToolService {

  @Output() positioningClosed = new EventEmitter();
  @Output() graveAdded = new EventEmitter();

  name = 'GraveAdd';
  initialData: any;
  positioningActive = false;
  translateInteraction: Translate;
  firstRender = true;
  graveAddLayer: any;
  currentType = undefined;
  currentRotation = 0;
  func: any;
  graveSizes = [];

  initialize() {
    this.mapHttpService.executeGetApiRequest('/cmentarz_rodzaj_grobus ').subscribe(result => {
      for (const rec of result['hydra:member']) {
        this.graveSizes.push({
          type: rec['@id'],
          width: rec.width,
          height: rec.height
        });
      }
    });
  }

  activate(mapService: MapService, initialData: any) {
    this.mapService = mapService;
    this.map = this.mapService.map;
    this.initialData = initialData;
    if (this.initialData) {
      this.currentType = this.initialData.rodzaj;
    }

    if (this.mapService.editableLayer) {
      this.initEditGrave();
    } else {
      this.initGraveAdd();
    }
  }

  deactivate() {
    if (this.translateInteraction) {
      this.map.removeInteraction(this.translateInteraction);
      delete this.translateInteraction;
    }

    this.map.removeLayer(this.graveAddLayer);
    this.firstRender = true;
  }

  initGraveAdd() {

    this.graveAddLayer = this.mapService.addTemporaryLayerWithStyle('Nowy grób', this.styleFactory.createPolygonStyle());
    this.graveAddLayer.layerConfig.printable = false;

    if (this.currentType) {
      this.renderGravePolygon(this.calculateGravePolygon(this.currentType, this.currentRotation));
      this.saveGrave();
    }
  }

  initEditGrave() {
    this.graveAddLayer = this.mapService.editableLayer;
    this.firstRender = false;
  }

  togglePositioning() {
    const me = this;
    this.func = function() {
      me.saveGrave();
    }

    if (!this.positioningActive) {
      const feature = this.graveAddLayer.getSource().getFeatures()[0];
      this.translateInteraction = new Translate({
        features : new Collection([feature])
      });
       this.map.addInteraction(this.translateInteraction);
       this.positioningActive = true;

      this.translateInteraction.on('translateend', this.func);

    } else {
      this.closePositioning();
    }


  }

  saveGrave() {
    this.graveAdded.emit(this.getGeometry());
  }

  getGeometry() {
    const geom = this.graveAddLayer.getSource().getFeatures()[0].getGeometry();
    return this.mapService.toGeojson(geom.clone().transform('EPSG:3857', this.mapService.mapConfig.dataProjection));
  }

  handlePropertyChange(change) {

    if (change.property === 'type') {
      this.currentType = change.value;
    } else if (change.property === 'rotation') {
      this.currentRotation = change.value;
    }

    if (this.positioningActive) {
      this.closePositioning();
    }

    this.redrawGravePolygon();
    this.saveGrave();
  }

  closePositioning() {
    this.translateInteraction.un('translateend', this.func);
    this.map.removeInteraction(this.translateInteraction);
    delete this.translateInteraction;
    this.positioningActive = false;
    this.positioningClosed.emit();
  }

  redrawGravePolygon() {
    const extent = this.calculateGravePolygon(this.currentType, this.currentRotation); // we need current center, so we dont clear layer first
    this.graveAddLayer.getSource().clear();
    this.renderGravePolygon(extent);
  }

  renderGravePolygon(extent: any) {

     const feature = this.createGraveFeature(extent);
     this.graveAddLayer.getSource().addFeature(feature);
  }

  createGraveFeature(extent) {
    let geometry;

    if (this.currentRotation === 0) {
      geometry = this.fromExtent(extent);
    } else {
      geometry = this.rotatePolygon(extent, this.currentRotation);
    }

    return new Feature(geometry);
  }

  getGraveSize(type: string) {
    for (const i in this.graveSizes) {
      if (this.graveSizes[i].type === type) {
        return [this.graveSizes[i].width, this.graveSizes[i].height];
      }
    }
    return [1, 1];
  }
  calculateGravePolygon(type: string, rotation: number) {

    const size = this.getGraveSize(type);

    let center;
    const projection = this.map.getView().getProjection();

    if (this.firstRender) {
      center = this.map.getView().getCenter();
      this.firstRender = false;
    } else {
      center = getCenter(this.graveAddLayer.getSource().getExtent());
    }

    return this.getForViewAndSize(projection, center, size);
  }

  getForViewAndSize(projection, center, size) {

    const proj2180 = getProjection('EPSG:2180');
    center = transform(center, projection, proj2180);
    const extent = [center[0] - (size[0] / 2), center[1] - (size[1] / 2), center[0] + (size[0] / 2), center[1] + (size[1] / 2)];
    return transformExtent(extent, proj2180, projection);

  }

  rotatePolygon(extent, angle) {
    const center = getCenter(extent);
    const p1 = this.rotatePoint([extent[0], extent[1]], angle, center);
    const p2 = this.rotatePoint([extent[0], extent[3]], angle, center);
    const p3 = this.rotatePoint([extent[2], extent[3]], angle, center);
    const p4 = this.rotatePoint([extent[2], extent[1]], angle, center);

    const flatCoords = [p1[0], p1[1], p2[0], p2[1], p3[0], p3[1], p4[0], p4[1], p1[0], p1[1]];

    return new Polygon(flatCoords, GeometryLayout.XY, [flatCoords.length]);
  }

  rotatePoint(point, angle, origin) {
    angle = angle * (-1);
    angle *= Math.PI / 180;
    const radius = this.distanceTo(point, origin);
    const theta = angle + Math.atan2(point[1] - origin[1], point[0] - origin[0]);
    const x = origin[0] + (radius * Math.cos(theta));
    const y = origin[1] + (radius * Math.sin(theta));
    return [x, y];
  }

  distanceTo (point, center) {
    return Math.sqrt(Math.pow(point[0] - center[0], 2) + Math.pow(point[1] - center[1], 2));
  }

  fromExtent(extent: any[]) {

    const minX = extent[0];
    const minY = extent[1];
    const maxX = extent[2];
    const maxY = extent[3];
    const flatCoordinates =
      [minX, minY, minX, maxY, maxX, maxY, maxX, minY, minX, minY];

    return new Polygon(flatCoordinates, GeometryLayout.XY, [flatCoordinates.length]);

  }

}
