import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {InfoData, InfoPanelComponent} from '../info-panel/info-panel.component';
import {MapEventBusService} from '../../services/map/map-event-bus-service';

@Component({
  selector: 'app-object-assign-panel',
  templateUrl: './object-assign-panel.component.html',
  styleUrls: ['./object-assign-panel.component.scss']
})
export class ObjectAssignPanelComponent implements OnInit {

  @Output() objectSelected = new EventEmitter();
  @Output() objectAssigned = new EventEmitter();
  @Output() assignmentCancelled = new EventEmitter();
  @Output() zoomToSelected = new EventEmitter();

  @ViewChild(InfoPanelComponent, { static: true }) infoPanel;
  panelVisible = false;

  rawData: any;
  infoData: InfoData[];
  displayedColumns: string[] = ['key', 'value'];
  selectedIdx: number;
  selectedId: string;

  constructor(private eventBus: MapEventBusService) { }

  ngOnInit() {
  }

  setInfoData(data: any) {

    if (data.length > 0) {
      this.panelVisible = true;
    }

    if (!data || data.length === 0) {
      this.infoData = [];
      return;
    }

    this.rawData = data;
    this.selectedId = data[0].id;

    this.infoData = [];
    for (const info of data) {

      const keyValue = [];
      const properties = info.properties;
      Object.keys(properties).forEach(key => {
        keyValue.push({key: key, value: properties[key]});
      })

      this.infoData.push({
        title: info.id,
        records: keyValue
      });
    }

    //this.selectInfo(this.selectedId);
    this.selectInfo(0);
  }

  cancel() {
    this.assignmentCancelled.emit();
  }

  assignInspection() {
    const selectedInfoId = this.getSelectedInfoId();
    const ref = {
      selectedId: selectedInfoId,
      geometry: this.getRawObject().geometry
    };

    this.objectAssigned.emit(ref);
  }

  selectInfo(idx: number) {
    this.selectedIdx = idx;
    this.selectedId = this.infoData[idx].title;
    const selectedRecord = this.getRawObject();
    this.eventBus.objectAssignSelected.emit(selectedRecord);
  }

  unselectInfo(idx: number) {
    if (this.selectedIdx === idx) {
      this.selectedIdx = undefined;
      this.eventBus.objectAssignUnselected.emit();
    }
  }

  getSelectedInfoId() {
    return this.selectedId;
  }

  getRawObject() {
    for (const rec of this.rawData) {
      if (rec.id === this.selectedId) {
        return rec;
      }
    }

    return null;
  }

  zoom() {
    this.zoomToSelected.emit();
  }

  clearData() {
    this.panelVisible = false;
    this.infoData = null;
    this.rawData = null;
    this.unselectInfo(this.selectedIdx);
  }
}
