import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiRequestPassword, ApiResetPassword } from '../models/users-api.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiUsersService {
  private _apiPrefix = `${environment.apiPrefix}/users`;
  private _apiPrefixRoles = `${environment.apiPrefix}/roles`;

  constructor(private _http: HttpClient) { }

  createNewUser(model) {
    return this._http.post(`${this._apiPrefix}/register`, model);
  }

  getUserDetails(id) {
    return this._http.get(`${this._apiPrefix}/${id}`);
  }

  editUser(model, id) {
    return this._http.put(`${this._apiPrefix}/${id}`, model);
  }

  deleteUser(id) {
    return this._http.put(`${this._apiPrefix}/${id}`, { deleted: true, enabled: false });
  }

  getList(pagination: any, sort?, filters?) {
    let httpParams = new HttpParams().set('page', pagination.pageIndex + 1).set('itemsPerPage', pagination.pageSize);

    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          httpParams = httpParams.append(key, filters[key]);
        }
      });
    }
    if (sort) {
      httpParams = httpParams.append(`order[${sort.active}]`, sort.direction);
    }
    return this._http.get(`${this._apiPrefix}`, { params: httpParams });
  }

  getRoles() {
    const params = new HttpParams().set('pagination', 'false');
    return this._http.get(`${this._apiPrefixRoles}`, { params: params });
  }

  exportList(filters?) {
    let filteredFilters = {};
    if (filters) {
      filters.subscribe((model) => {
        filteredFilters = model;
      });
    }

    let params = new HttpParams().set('pagination', 'false');
    if (filteredFilters) {
      Object.keys(filteredFilters).forEach(key => {
        if (filteredFilters[key]) {
          params = params.append(key, filteredFilters[key]);
        }
      });
    }

    const reqHeader = new HttpHeaders({ 'accept': 'application/vnd.ms-excel' });
    return this._http.get(`${this._apiPrefix}`, { params: params, headers: reqHeader, responseType: 'blob', observe: 'response' });
  }

  changePassword(model) {
    return this._http.put(`${this._apiPrefix}/${localStorage.getItem('userId')}/change-password`, model);
  }

  changePasswordByAdmin(model, id) {
    return this._http.put(`${this._apiPrefix}/${id}/change-password-by-admin`, model);
  }

  forgotPasswordRequest(model: ApiRequestPassword) {
    return this._http.post(`${this._apiPrefix}/forgot-password-request`, model);
  }

  forgotPasswordReset(model: ApiResetPassword) {
    return this._http.post(`${this._apiPrefix}/forgot-password-reset`, model);
  }
}
