import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';
import Feature from 'ol/Feature';
import {MapEventBusService} from '../services/map/map-event-bus-service';
import {MapConfigService} from '../services/map-config-servce';
import {StyleFactoryService} from '../services/style-factory-service';
import {MapHttpService} from '../services/map-http-service';
import {Router} from '@angular/router';

@Injectable()
export class InfoToolService extends GenericToolService {

  @Output() featureInfoReceived = new EventEmitter();

  name = 'Info';
  infoLayers: any[];
  tempLayer: any;
  registerableFunc: any;

  constructor( private eventBus: MapEventBusService,
              mapConfigService: MapConfigService, styleFactory: StyleFactoryService, mapHttpService: MapHttpService, _router: Router) {
    super(mapConfigService, styleFactory, mapHttpService, _router);
  }

  activate(mapService: MapService) {

    this.mapService = mapService;
    this.map = this.mapService.map;

    this.tempLayer = this.mapService.addTemporaryLayerWithStyle('Info', this.styleFactory.createPolygonStyle());
    this.tempLayer.layerConfig.printable = false;

    this.infoLayers = this.mapService.getLayersByProperty('info', true);

    if (!this.infoLayers || this.infoLayers.length === 0) {
      console.error('[ERROR] No info layers specified in map configuration. Info tool will not work correctly');
    }

    // passing scope to singleclick not working in angular;
    const me = this;
    this.registerableFunc = function (evt) {
      me.executeGetFeatureInfo(evt);
    };

    this.map.on('singleclick', this.registerableFunc);

    this.eventBus.infoSelected.subscribe((feature) => {
      this.mapService.addSingleData(this.tempLayer, feature, false,  false);
    });

    this.eventBus.infoUnselected.subscribe(() => {
      this.tempLayer.getSource().clear();
    });

  }

  getRoutableLayer(layers) {

    for (const i in layers) {
      if (layers[i].layerConfig && layers[i].layerConfig.routingInfo) {
        return layers[i];
      }
    }
    return null;
  }

  replaceUrlParam (url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = '';
    }
    const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
  }

  executeGetFeatureInfo (evt) {

    const url = this.generateGetFeatureInfoUrl(evt);

    this.mapHttpService.getFeatureInfo(url).subscribe(response => {
      if (response && response.features && response.features.length > 0) {
        //2020.06 - now the geometry is added on expanding row in info panel
        //this.addFeaturesToMap(response.features);

        this.mapHttpService.getInfo(this.buildInfoRequest(response.features)).subscribe( res => {
          this.featureInfoReceived.emit(this.parseInfoResponse(res, response.features));
        });
      }
    });
  }

  parseInfoResponse(infoResponse: any, features: any[]): any[] {

    const result = [];
    for (const info of infoResponse) {
      const obj = {
        layerName: info.layerName,
      };
      const layer = this.findInfoLayer(info.layerName);

      if (layer) {
        let title = layer.layerConfig.name;
        if (layer.layerConfig.infoConfig && layer.layerConfig.infoConfig.titleProperty) {
          title += ' (' + info.attributes[0][layer.layerConfig.infoConfig.titleProperty] + ')';
        }
        obj['title'] = title;
      } else {
        obj['title'] = info.layerName;
      }
      const attributes = info.attributes[0];
      const keys = Object.keys(attributes);
      const attrs = [];

      for (const key of keys) {
        if (key === 'geom') {
          continue;
        }
        const attr = {};
        attr['name'] = key;
        attr['label'] = key;
        attr['type'] = 'String';
        attr['value'] = this.transformValue(attributes[key]);
        attrs.push(attr);
      }
      obj['attributes'] = attrs;

      obj['feature'] = this.getObjectFeature(obj, features);
      result.push(obj);
    }
    return result;
  }

  getObjectFeature(obj: any, features: any[]) {
    const objId = obj.layerName + '.' + this.getIdAttribute(obj.attributes);

    for (const feature of features) {
      if (feature.id === objId) {
        return feature;
      }
    }

    return null;
  }

  getIdAttribute(attributes: any[]) {
    for (const attribute of attributes) {
      if (attribute.name === 'id') {
        return attribute.value;
      }

      return null;
    }
  }
  transformValue(value: any) {
    if (typeof value === 'string' && (value.indexOf('http') === 0 || value.indexOf('https') === 0)) {
      return '<a href="' + value + '">' + value + '</a>';
    } else {
      return value;
    }
  }
  buildInfoRequest(features: any) {
    const infoRequest = [];
    for (const feature of features) {
      const fidParts = feature.id.split('.');
      const properties = feature.properties;
      const layerName = fidParts[0];
      const layer = this.findInfoLayer(layerName);
      let key: string;
      let value: string;
      if (layer.layerConfig.infoConfig && layer.layerConfig.infoConfig.idProperty) {
        key = layer.layerConfig.infoConfig.idProperty;
        if (properties[layer.layerConfig.infoConfig.idProperty]) {
          value = properties[layer.layerConfig.infoConfig.idProperty];
        } else {
          value = fidParts[1];
        }
      } else {
        key = 'id';
        if (properties.id) {
          value = properties.id;
        } else {
          value = fidParts[1];
        }
      }
      const request = {
        layerName: layerName
      }
      request[key] = value;

      infoRequest.push(request);
    }
    return {items: infoRequest};
  }

  findInfoLayer(layerName: string) {
    for (const layer of this.infoLayers) {
      if (layer.layerConfig.layerName.indexOf(layerName) >= 0) {
        return layer;
      }
    }
  }
  addFeaturesToMap(features) {
    this.tempLayer.getSource().clear();
    const mapFeatures = [];
    for (const f of features) {
      //2020.06 disabled transform as map crs is set in getFeatureInfoRequest so data is responded in correct srs
      //const geom = this.mapService.fromGeojson(f.geometry).transform(this.mapService.mapConfig.dataProjection, this.mapService.getSrs());
      const geom = this.mapService.fromGeojson(f.geometry);
      const feature = new Feature({
        geometry: geom
      });
      mapFeatures.push(feature);
    }
    this.tempLayer.getSource().addFeatures(mapFeatures);
  }

  generateGetFeatureInfoUrl(evt) {

    let layers = '';
    let first = true;
    for (const i in this.infoLayers) {
      if (first) {
        layers = this.infoLayers[i].getSource().getParams().LAYERS;
        first = false;
      } else {
        layers += ',' + this.infoLayers[i].getSource().getParams().LAYERS;
      }
    }

    return this.mapService.generateGetFeatureInfoUrl(evt, layers, this.infoLayers[0]);

  }

  deactivate() {
    if (this.registerableFunc) {
      this.map.un('singleclick', this.registerableFunc);
    }
    this.map.removeLayer(this.tempLayer);
    this.tempLayer = undefined;
  }
}
