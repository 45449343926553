import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-grave-panel',
  templateUrl: './grave-panel.component.html',
  styleUrls: ['./grave-panel.component.scss']
})
export class GravePanelComponent implements OnInit {

  @Output() propertyChanged = new EventEmitter();
  @Output() togglePositioning = new EventEmitter();
  @Output() zoomEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();

  public selectedGraveType = 'SINGLE';
  public selectedRotation = 0;
  positioningIcon = 'aspect_ration';

  constructor() { }

  ngOnInit() {
  }

  onChange(property, value) {
    this.propertyChanged.emit({property: property, value: value});
  }

  positioningPressed() {
    this.togglePositioning.emit();
    this.togglePositioningButtons();
  }

  togglePositioningButtons() {
    if (this.positioningIcon === 'clear') {
      this.positioningIcon = 'aspect_ration';
    } else {
      this.positioningIcon = 'clear';
    }
  }

  zoomPressed() {
    this.zoomEvent.emit();
  }

  saveGrave() {
    this.saveEvent.emit({type: this.selectedGraveType});
  }

  stepUp(input: HTMLInputElement): void {
    this.selectedRotation++;
    this.onChange('rotation', this.selectedRotation);
  }


  stepDown(input: HTMLInputElement): void {
    this.selectedRotation--;
    this.onChange('rotation', this.selectedRotation);
  }
}
