import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService {
  private _apiPrefix = environment.apiPrefix;

  constructor(private _http: HttpClient) { }

  login(model) {
    const userModel = model;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this._apiPrefix}/token/obtain`, userModel, { headers: reqHeader });
  }
}
