import { NgModule } from '@angular/core';

// here we will add material components
import {
  MatSidenavModule,
  MatTabsModule,
  MatListModule,
  MatIconModule,
  MatToolbarModule,
  MatSelectModule,
  MatMenuModule,
  MatButtonModule,
  MatInputModule,
  MatCheckboxModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatTreeModule,
  MatRippleModule,
  MatDialogModule,
  MatProgressBarModule,
  MatAutocompleteModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  imports: [
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    MatCardModule,
    MatDividerModule,
    DragDropModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule.setLocale('pl-PL')
  ],
  exports: [
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatDividerModule,
    DragDropModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTreeModule,
    MatRippleModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule
  ],
})

export class MaterialModule { }
