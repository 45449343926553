import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-attributes-form',
  templateUrl: './attributes-form.component.html',
  styleUrls: ['./attributes-form.component.scss']
})
export class AttributesFormComponent implements OnInit {

  @Output() saveAttributes = new EventEmitter();

  attributes: any[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.newObject) {
      this.attributes = this.stripValues(data.attributes);
    } else {
      this.attributes = data.attributes;
    }
  }

  ngOnInit() {
  }

  stripValues(attributes: any[]): any[] {
    const stripped = [];
    for (const attribute of attributes) {
      stripped.push({key: attribute.key, value: null});
    }
    return stripped;
  }

  save() {
    const result = {};
    for (const attribute of this.attributes) {
      result[attribute.key] = attribute.value;
    }
    this.saveAttributes.emit(result);
  }
}
