import { AppInitService } from './../../services/app-init.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ROUTES } from '../../configs/sidebar-routes.config';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() closeNav: EventEmitter<any> = new EventEmitter<any>();

  menuList = ROUTES.find(item => item.code === environment.availableApplication).children;
  availableModule = environment.availableApplication;

  constructor(private _appInitService: AppInitService) {
    this._appInitService.changeApplication.subscribe((item: any) => {
      this.menuList = item.children;
    });
  }

  ngOnInit() {
  }

  close() {
    this.closeNav.emit();
  }

  expand(item) {
    item.collapsed = !item.collapsed;
  }
}
