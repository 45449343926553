import { Injectable } from '@angular/core';
import proj4 from 'proj4/dist/proj4.js';
import { register } from 'ol/proj/proj4.js';

@Injectable()
export class ProjectionService {

  projections = [
    {code: 'EPSG:2180', extent: [-2861425.28, -1057534.86, 729176.20, 4085811.84]},
    {code: 'EPSG:3328', extent: [132323.23, 160259.18, 819802.28, 814290.41]},
    {code: 'EPSG:2176', extent: [2533821.25, 4104230.63, 5771252.26, 9409070.99]},
    {code: 'EPSG:2177', extent: [3236256.79, 4207696.11, 6739913.31, 9395740.70]},
    {code: 'EPSG:2178', extent: [3936040.28, 4324573.90, 7707927.89, 9384064.69]},
    {code: 'EPSG:2179', extent: [4633213.50, 4456045.25, 8675382.71, 9374073.29]},
    {code: 'EPSG:3120', extent: [4129530.92, 5309841.02, 4834101.51, 5945973.70]},
    {code: 'EPSG:2172', extent: [4064428.91, 5387678.39, 4772978.43, 6019326.73]},
    {code: 'EPSG:2173', extent: [3290985.24, 5493058.91, 3958791.74, 6167633.05]},
    {code: 'EPSG:2174', extent: [3517796.13, 5333104.90, 4182619.71, 6010454.35]},
    {code: 'EPSG:2175', extent: [-115380.55, 740867.61, 570111.78, 1397372.93]}
  ];

  constructor() {
    proj4.defs('EPSG:2176', '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:2177', '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:2178', '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:2179', '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:2180', '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);
  }

  getExtent(code: string) {
    for (const i in this.projections) {
      if (this.projections[i].code === code) {
        return this.projections[i].extent;
      }
    }
    return null;
  }
}
