import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-print-panel',
  templateUrl: './print-panel.component.html',
  styleUrls: ['./print-panel.component.scss']
})
export class PrintPanelComponent implements OnInit {

  @Output() propertyChanged = new EventEmitter();
  @Output() togglePositioning = new EventEmitter();
  @Output() print = new EventEmitter();

  printConfig: any;
  public selectedDpi = '150';
  public selectedScale: number;
  public selectedFormat: string;
  public selectedType = 'pdf';
  public selectedRotation = 0;
  public selectedTitle = '';

  constructor() { }

  ngOnInit() {
  }

  setPrintConfig(printConfig: any) {
    this.printConfig = printConfig;
    this.selectedFormat = this.printConfig.selectedTemplate.name;
    this.selectedScale = this.printConfig.closestScale;
  }

  onChange(property, value) {
    this.propertyChanged.emit({property: property, value: value});
  }

  positioningPressed() {
    this.togglePositioning.emit();
  }

  printPressed() {
    const params = {
      dpi: this.selectedDpi,
      type: this.selectedType,
      title: this.selectedTitle
    }
    this.print.emit(params);
  }

  stepUp(input: HTMLInputElement): void {
    this.selectedRotation++;
    this.onChange('rotation', this.selectedRotation);
  }


  stepDown(input: HTMLInputElement): void {
    this.selectedRotation--;
    this.onChange('rotation', this.selectedRotation);
  }
}
