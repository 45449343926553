import {Injectable} from '@angular/core';
import {Tile as TileLayer, Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import TileWMS from 'ol/source/TileWMS.js';
import {OSM} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {bbox as bboxStrategy} from 'ol/loadingstrategy.js';
import {all as allStrategy} from 'ol/loadingstrategy.js';
import VectorSource from 'ol/source/Vector.js';
import {StyleFactoryService} from './style-factory-service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LayerFactoryService {

  constructor(private styleFactory: StyleFactoryService, private _http: HttpClient) {}

  static createWmsLayer(config: any): any {
    const source = new TileWMS({
      url: config.url,
      params: {'LAYERS': config.layerName, TILED: true},
      serverType: 'geoserver',
      transition: 0
    });

    return new TileLayer({
      name: config.name,
      source: source,
      format: 'image/png8'
    });

  }

  createWfsLayer(config: any, srsname: string): any {

    srsname = 'EPSG:3857';
    let strategy;
    if (config.strategy && config.strategy === 'all') {
      strategy = allStrategy;
    } else {
      strategy = bboxStrategy;
    }

    // const urlFunc = function(extent) {
    //   const random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    //   //const random = '';
    //   return config.url + '?service=WFS&' + 'version=1.1.0&request=GetFeature&typename=' + config.layerName +
    //     '&' + 'outputFormat=application/json' + '&format_options=callback:loadFeatures_' + random +
    //     '&srsname=' + srsname + '&bbox=' + extent.join(',') + ',' + srsname;
    // };

    const me = this;

    const source = new VectorSource({
      format: new GeoJSON(),
      loader: function(extent, resolution, projection) {
        if (!source.savingEdits && source.currentlyEdited) {
          console.log('Layer is currently edited. No refresing would be applied');
          return;
        }
        const random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
        const callback = 'ng_jsonp_callback_2';
        const jsonPurl = config.url + '?service=WFS&' + 'version=1.1.0&request=GetFeature&typename=' + config.layerName + '&' +
          'outputFormat=text/javascript' + '&format_options=callback:' + callback + '&srsname=' + srsname + '&bbox=' + extent.join(',') + ',' + srsname;
        //+ '&JSONP_CALLBACK=' + callback;

        const url = config.url + '?service=WFS&' + 'version=1.1.0&request=GetFeature&typename=' + config.layerName + '&' +
          'outputFormat=application/json&srsname=' + srsname + '&bbox=' + extent.join(',') + ',' + srsname;

        me._http.get(url).subscribe((response) => {
          source.addFeatures(source.getFormat().readFeatures(response));
        });

        // me.executeJsonp(url, callback).subscribe((response) => {
        //   source.addFeatures(source.getFormat().readFeatures(response));
        // });
      },
      //url: urlFunc,
      strategy: strategy
    });

    return new VectorLayer({
      source: source,
      style: this.styleFactory.createWfsStyle()
    });

  }

  createLayersFromConfiguration(layersConfig: any, srs: string): any {
    return this.createLayers(layersConfig, srs);
  }

  createLayers(layersConfig: any, srs: string): any {
    const layers = [];
    for (const config of layersConfig) {
      layers.push(this.createLayer(config, srs));
    }
    return layers;
  }
  createLayer(config: any, srs: string): any {
    let layer;

    if (config.type === 'osm') {
      layer = new TileLayer({
        source: new OSM()
      });
    } else if (config.type === 'wms') {
      layer = LayerFactoryService.createWmsLayer(config);
    } else if (config.type === 'group') {
      layer = this.createGroupLayer(config, srs);
    } else if (config.type === 'wfs') {
      layer = this.createWfsLayer(config, srs);
    }

    layer.layerConfig = config;
    layer.layerConfig['printable'] = true;
    layer.layerConfig['format'] = 'image/png';
    return layer;
  }

  createGroupLayer(config: any, srs: string) {
   return new LayerGroup({
      name: config.name,
      layers: this.createLayers(config.layers, srs)
    });
  }
}
