import { Injectable } from '@angular/core';
import { DatasetClass } from '../../../common/dataset/dataset.class';
import { EntriesItem } from '../models/repairs.model';
import { ApiRepairsService } from './repairs-api.service';

@Injectable()
export class RepairsService extends DatasetClass<EntriesItem> {
  constructor(private _apiRepairsService: ApiRepairsService) {
    super(_apiRepairsService);
  }
}
