import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapConfigService} from '../../services/map-config-servce';

@Component({
  selector: 'app-map-profile-chooser',
  templateUrl: './map-profile-chooser.component.html',
  styleUrls: ['./map-profile-chooser.component.scss']
})
export class MapProfileChooserComponent implements OnInit {

  @Input() active: boolean;
  @Input() selectedProfile: string;
  @Output() profileChanged = new EventEmitter();

  availableProfiles: any[];

  constructor(private mapConfigService: MapConfigService) { }

  ngOnInit() {

    if (typeof this.active === 'string') {
      this.active = (this.active === 'true');
    }

    if (this.active === true) {
      this.mapConfigService.getProfileConfig('cmentarzs').subscribe((data) => {
        this.availableProfiles = data;
      });
    }
  }

  onSelectionChange() {
    this.profileChanged.emit(this.selectedProfile);

  }
}
