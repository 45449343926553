import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-pending-changes-confirmation',
  templateUrl: './pending-changes-confirmation.component.html',
  styleUrls: ['./pending-changes-confirmation.component.scss']
})
export class PendingChangesConfirmationComponent implements OnInit {

  @Output() confirmationAction = new EventEmitter();
  msg: string;
  applyButtonText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.eventData.origin === 'changeLayer') {
      this.msg = 'Zmiana warstwy spowoduje utracenie niezapisanych zmian. Czy na pewno chcesz zmienić warstwę?';
      this.applyButtonText = 'Zmień warstwę';
    } else {
      this.msg = 'Istnieją niezapisane zmiany. Czy na pewno chcesz zakończyć?';
      this.applyButtonText = 'Zakończ edycję';
    }
  }

  ngOnInit() {
  }

  send(actionType: string) {
    this.confirmationAction.emit({eventData: this.data.eventData, actionType: actionType});
  }
}
