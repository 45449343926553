import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';

@Injectable()
export class PrintService {

  private printUrl = 'http://localhost:8080/geoserver/pdf/info.json?';

  constructor(private _http: HttpClient) { }

  getPrintConfig(): Observable<any> {
    return this._http.get<any>(this.printUrl);
  }

}
