import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MapEventBusService} from '../../services/map/map-event-bus-service';

export interface InfoData {
  title: string;
  records: InfoRecord[];
}

export interface InfoRecord {
  key: string;
  value: any;
}

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {

  infoData: InfoData[];
  displayedColumns: string[] = ['key', 'value'];
  selectedIdx: number;
  rawData: any;

  constructor(private eventBus: MapEventBusService) { }

  ngOnInit() {
  }

  setInfoData(data: any) {
    if (!data || data.length === 0) {
      this.infoData = [];
      return;
    }

    this.rawData = data;

    this.infoData = [];
    for (const info of data) {

      const keyValue = [];
      for (const attribute of info.attributes) {
        keyValue.push({key: attribute.label, value: attribute.value});
      }

      this.infoData.push({
        title: info.title,
        records: keyValue
      });
    }

    if (data.length === 1) {
      this.selectInfo(0);
    }
  }

  selectInfo(idx: number) {
    this.selectedIdx = idx;
    const selectedRecord = this.rawData[idx];
    this.eventBus.infoSelected.emit(selectedRecord.feature.geometry);
  }

  unselectInfo(idx: number) {
    if (this.selectedIdx === idx) {
      this.selectedIdx = undefined;
      this.eventBus.infoUnselected.emit();
    }
  }
}
